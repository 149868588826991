import React from "react";
import { Badge, Button, SubHeading } from "../../../components";
import { exportAsImage, formatDate, formatNaira } from "../../../utils";
import { useRef } from "react";
import { useColors } from "../../../hooks/useColors";
export const TransactionDetails = ({ receiptDetails, showModal }) => {
  const { logo } = useColors();
  const receiptWrapper = useRef(null);
  return (
    <div>
      <div ref={receiptWrapper} className=" p-4 ">
        <div
          className={`bg-white text-primary font-bold h-[50px]  rounded-lg text-[20px]`}
        >
          {logo ? <img src={logo} className="w-[70px]" /> : " SUMMER-MFB"}
        </div>
        <div className="text-center">
          <SubHeading>Transaction Receipt</SubHeading>
          <p className="text-gray-500 text-sm mt-3">
            Generated on {formatDate(receiptDetails?.current_date)}
          </p>
        </div>
        <div>
          <dl className="grid grid-cols-12 gap-4 mt-5 text-[14px] capitalizerounded-[7px] border-b pb-3">
            <dt className="col-span-6 text-primary">Transaction Amount</dt>
            <dt className="col-span-6 text-right text-gray-700">
              {receiptDetails?.amount}
            </dt>
          </dl>
          <dl className="grid grid-cols-12 gap-4 mt-5 text-[14px] capitalizerounded-[7px] border-b pb-3">
            <dt className="col-span-6 text-primary">Transaction Status</dt>
            <dt className="col-span-6 text-right">
              <Badge status={receiptDetails?.record_type}>
                {receiptDetails?.record_type}
              </Badge>
            </dt>
          </dl>
          {/* <dl className="grid grid-cols-12 gap-4 mt-5 text-[14px] capitalizerounded-[7px] border-b pb-3">
            <dt className="col-span-6 text-primary">Account Name</dt>
            <dt className="col-span-6 text-right text-gray-700">
              {receiptDetails?.destination_account_name}
            </dt>
          </dl> */}
          {/* <dl className="grid grid-cols-12 gap-4 mt-5 text-[14px] capitalizerounded-[7px] border-b pb-3">
            <dt className="col-span-6 text-primary">Account Number</dt>
            <dt className="col-span-6 text-right text-gray-700">
              {receiptDetails?.destination_account_number}
            </dt>
          </dl> */}
          {/* <dl className="grid grid-cols-12 gap-4 mt-5 text-[14px] capitalizerounded-[7px] border-b pb-3">
            <dt className="col-span-6 text-primary">Bank Name</dt>
            <dt className="col-span-6 text-right text-gray-700">
              {receiptDetails?.destination_bank_name}
            </dt>
          </dl> */}
          <dl className="grid grid-cols-12 gap-4 mt-5 text-[14px] capitalizerounded-[7px] border-b pb-3">
            <dt className="col-span-6 text-primary">Reference</dt>
            <dt className="col-span-6 text-right text-gray-700">
              {receiptDetails?.instrument_no}
            </dt>
          </dl>
          {receiptDetails?.narration && (
            <dl className="grid grid-cols-12 gap-4 mt-5 text-[14px] items-center capitalizerounded-[7px] border-b pb-3">
              <dt className="col-span-6 text-primary">Narration</dt>
              <dt className="col-span-6 text-right text-gray-700">
                {receiptDetails?.narration}
              </dt>
            </dl>
          )}

          <dl className="grid grid-cols-12 gap-4 mt-5 text-[14px] capitalizerounded-[7px] border-b pb-3">
            <dt className="col-span-6 text-primary">Transaction Date & Time</dt>
            <dt className="col-span-6 text-right text-gray-700">
              {formatDate(receiptDetails?.current_date)}
            </dt>
          </dl>
          <dl className="grid grid-cols-12 gap-4 mt-5 text-[14px] capitalizerounded-[7px] border-b pb-3">
            <dt className="col-span-6 text-primary">Posting type</dt>
            <dt className="col-span-6 text-right text-gray-700">
              {receiptDetails?.posting_type}
            </dt>
          </dl>
        </div>
      </div>
      <div className="flex justify-end mt-2">
        <Button
          variant="outline"
          onClick={() => {
            exportAsImage(receiptWrapper.current, "receipt");
            showModal();
          }}
        >
          <p className="text-primary font-semibold"> Download</p>
        </Button>
      </div>
    </div>
  );
};
