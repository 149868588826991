import { Link } from "react-router-dom";
import { formatDate, formatNaira } from "../../../utils";
import { Badge } from "../../../components";

export const LoanTable = ({ data, initialSerialNumber }) => {
  return (
    <div>
      <div className="rounded-sm overflow-x-auto mb-4">
        <table className="min-w-full whitespace-nowrap ">
          <thead className="bg-[#FEF9F4]">
            <tr>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                S/N
              </th>

              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Product Name
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Interest
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Loan amount
              </th>

              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[12px]">
                Loan tenure
              </th>

              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                authorizers
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                status
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Time created
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[12px]">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-800">
            {data?.map((item, i) => (
              <tr key={item.id}>
                <td className="px-3 py-6 text-[13px] whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {i + initialSerialNumber}.
                </td>

                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {item?.name}
                </td>

                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {formatNaira(item?.interest_rate)}
                </td>
                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  <div>
                    <p>
                      {formatNaira(item?.min_loan_amount)} to <br />{" "}
                      {formatNaira(item?.max_loan_amount)}
                    </p>
                  </div>
                </td>

                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  <div className=" flex items-center gap-1">
                    <p> {item?.min_tenor} to</p>

                    <p>{item?.max_tenor}</p>
                    <p>{item?.tenor_type}</p>
                  </div>
                </td>

                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {item?.approval?.minimum_no_of_approvals} Authorizer
                </td>
                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  <Badge status={item?.status}>{item?.status}</Badge>
                </td>
                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {formatDate(item?.created_at)}
                </td>
                <td className="px-3 py-6 whitespace-nowrap text-[13px]    text-primary border-b border-[#c0c0c052]">
                  <Link
                    className="text-primary font-semibold px-3 py-1 border  border-[#e7802062] rounded-md block"
                    to={`/loan-products/product/${item?.id}`}
                  >
                    view product
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
