import React from "react";

export const OptionButton = ({ checked, id, title, name, onChange, value }) => {
  return (
    <div className="relative flex items-start cursor-pointer">
      <div className="flex h-6 items-center">
        <input
          type="radio"
          id={id}
          defaultChecked={checked}
          name={name}
          onChange={onChange}
          value={value}
          className="h-4 w-4 rounded border-[#E78020] text-primary focus:ring-primary cursor-pointer"
        />
        <div className="ml-3 text-sm leading-6 cursor-pointer">
          <label htmlFor={id} className="text-primary">
            {title}
          </label>
        </div>
      </div>
    </div>
  );
};
