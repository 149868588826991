import { useForm } from "react-hook-form";
import { Button, Input} from "../../../components";
import { useMutation} from "@tanstack/react-query";
import { userService } from "../../../services/users/user.service";

export const AddUser = ({ showModal, refetch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { mutate, isPending } = useMutation({
    mutationKey: ["create-reps"],
    mutationFn: (data) => userService.createRep(data),
    onSuccess: () => {
      showModal();
      refetch();
    },
  });
  const onSubmit = (data) => {
    const payload = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      phone_number: data?.phone,
    };
    mutate(payload);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
      <Input
        id="first_name"
        label="First Name"
        {...register("first_name", { required: true })}
        error={errors.first_name && "First name is required"}
      />
      <Input
        id="last_name"
        label="Last Name"
        {...register("last_name", { required: true })}
        error={errors.last_name && "Last name is required"}
      />

      <Input
        label="Email Address"
        id="email"
        {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
        error={errors.email && "Email address is required"}
      />
      <Input
        label="Phone number"
        id="phone"
        {...register("phone", { required: true })}
        error={errors.phone && "Phone number is required"}
      />

      <Button type="submit" disabled={isPending} isFullWidth>
        Create User
      </Button>
    </form>
  );
};
