import { Button, Select, Input } from "../../../../components";

export const FormStep3 = ({
  errors,
  control,
  register,
  handleSubmit,
  handleNext,
  handlePrev,
  currentStep,
}) => {
  const onSubmit = () => {
    handleNext();
  };
  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center justify-between pt-3">
        <p className="font-bold text-lg">Other Info</p>
        <p>Step {`${currentStep}/4`}</p>
      </div>
      <Input
        label="Referal phone number"
        id="referral_phone_no"
        {...register("referral_phone_no", { required: true })}
        error={errors.referral_phone_no && "Referal phone number is required"}
      />
      <Input
        label="Referral name"
        id="referral_name"
        {...register("referral_name", { required: true })}
        error={errors.referral_name && "Referral name is required"}
      />
      <Input
        label="Other account information source"
        id="other_account_information_source"
        {...register("other_account_information_source", { required: true })}
        error={
          errors.other_account_information_source &&
          "Other account info source is required"
        }
      />

      <Input
        label="Account officer code"
        id="account_officer_code"
        {...register("account_officer_code", { required: true })}
        error={
          errors.account_officer_code && "Account officer code is required"
        }
      />

      <Select
        label="Account tier"
        name="account_tier"
        control={control}
        options={[
          { label: 1, value: 1 },
          { label: 2, value: 2 },
        ]}
        error={errors.account_tier && "Account tier is required"}
      />
      <Select
        label="Notifiction preference"
        name="notification_preference"
        control={control}
        options={[
          { label: "SMS", value: 0 },
          { label: "Email", value: 1 },
        ]}
        error={
          errors.notification_preference &&
          "Notification preference is required"
        }
      />

      <div className="flex items-center justify-between pt-4">
        <Button type="button" variant="outline" onClick={handlePrev}>
          Back
        </Button>
        <Button type="submit">Continue</Button>
      </div>
    </form>
  );
};
