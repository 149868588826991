import http from "../../plugins/http";
import { notifyError, notifySuccess } from "../../utils/toast";

class AuthService {
  async preLogin(payload) {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASE_URL}/organization-representatives/pre-login`,
        payload
      );
      if (response.data?.auth_token) {
        notifySuccess(response?.message ?? "Login successful");
        localStorage.setItem("token", response.data?.auth_token);
        http.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data?.auth_token}`;
      } else {
        notifySuccess("Answer security question");
      }

      return response.data;
    } catch (error) {
      notifyError(error?.response.data?.message);
      return Promise.reject(error);
    }
  }

  async login(payload) {
    try {
      const { data } = await http.post(
        `${process.env.REACT_APP_BASE_URL}/organization-representatives/login`,
        payload
      );
      if (data?.auth_token) {
        localStorage.setItem("token", data?.auth_token);
        http.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data?.auth_token}`;
      }
      notifySuccess(data?.message ?? "Login successful");
      return data;
    } catch (error) {
      notifyError(error?.response?.data?.message?.message);
      return Promise.reject(error);
    }
  }

  async forgotPassword(payload) {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASE_URL}/organization-representatives/forgot-password`,
        payload
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async resetPassword(payload) {
    try {
      const response = await http.post(
        "/organization-representatives/reset-password",
        payload
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async changePassword(payload) {
    try {
      const response = await http.put(
        `${process.env.REACT_APP_BASE_URL}/organization-representatives/change-password`,
        payload
      );
      notifySuccess(response?.data?.message ?? "Password changed successfully");
      return response;
    } catch (error) {
      notifyError(
        error.response?.data?.message ??
          "Unable to change password, please try again"
      );
      throw new Error(error);
    }
  }

  async updateSecurityQuestion(payload) {
    try {
      const response = await http.put(
        `${process.env.REACT_APP_BASE_URL}/organization-representatives/update-security-questions`,
        payload
      );
      notifySuccess(
        response?.message ?? "Security question updated successfully"
      );
      return response;
    } catch (error) {
      notifyError(
        error.response?.data?.message ??
          "Unable to update security question, please try again"
      );
      throw new Error(error);
    }
  }
}

export const authService = new AuthService();
