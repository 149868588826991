import React from "react";
import ReactApexChart from "react-apexcharts";
import { formatNaira } from "../../utils";
import ContentLoader from "react-content-loader";

const Chart = ({ data, isloading }) => {
  const options = {
    chart: {
      type: "area",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },

      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "Loan Products",
      align: "left",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return "₦" + val;
        },
      },
    },
    xaxis: {
      type: "category",
      categories: data?.items?.map((item) => item?.name),
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return "₦" + val;
        },
      },
    },
  };

  const series = [
    {
      name: "Min Amount",
      data: data?.items?.map((item) => item?.min_loan_amount),
      color: "#e78020",
    },
    {
      name: "Max Amount",
      data: data?.items?.map((item) => item?.max_loan_amount),
      color: "#00984C",
    },
  ];

  return (
    <div id="chart">
      {isloading ? (
        <ContentLoader viewBox="0 0 380 70">
          <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
        </ContentLoader>
      ) : (
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={550}
        />
      )}
    </div>
  );
};

export default Chart;
