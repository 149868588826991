import http from "../plugins/http";
import { notifyError, notifySuccess } from "../utils/toast";

class RoleService {
  async getRoles() {
    try {
      const { data } = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/organizations/settings/roles`
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async createRoles(payload) {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_CONSOLE_URL}/organizations/settings/roles`,
        payload
      );
      notifySuccess(response?.message);
      return response?.data;
    } catch (error) {
      notifyError(error?.response?.data?.message);
      throw new Error(error);
    }
  }
  async roleById(roleId) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/organizations/settings/roles/${roleId}`
      );

      return response?.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async updateRolePermission(roleId, permissions) {
    try {
      const response = await http.patch(
        `${process.env.REACT_APP_CONSOLE_URL}/organizations/settings/roles/${roleId}/permissions`,
        { permissions }
      );
      notifySuccess(response?.message);
      return response?.data;
    } catch (error) {
      notifyError(error?.response?.data?.message);
      throw new Error(error);
    }
  }
}

export const roleService = new RoleService();
