import { Menu, Transition } from "@headlessui/react";
import {
  ArrowCircleDown,
  ArrowCircleUp,
  Check,
  Setting2,
  User,
} from "iconsax-react";
import React from "react";
import { useState, Fragment } from "react";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useModal } from "../../hooks";
import { LogoutPrompt } from "../LogoutPrompt/LogoutPrompt";
import avatar from "../../assets/img/smile.avif";
import { Notifications } from "../Notification/Notification";
import { data } from "../Notification/Data";
import { useLogout } from "../../hooks/useLogout";
export const Switch = ({
  title = "  Sabo, Yaba Lagos",
  details = "  sabobranch@lapo.com",
}) => {
  const { Modal, showModal } = useModal();
  const [notify, setNotify] = useState(false);
  const { Modal: Notify, showModal: showNotification } = useModal();
  const { handleLogout } = useLogout();
  const navigate = useNavigate();
  const { dropdownRef, handleClickOutside, isOpen, setIsOpen } =
    useClickOutside();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className=" flex items-center gap-10">
      <div
        className="w-[36px] h-[36px] flex items-center justify-center border bg-[#e7802028] rounded-full cursor-pointer"
        onClick={() => {
          navigate("/settings");
        }}
      >
        <Setting2 className="w-5 h-5 text-primary" />
      </div>
      {/* <div
        className="w-[36px] h-[36px] flex items-center justify-center bg-[#e7802028] rounded-full cursor-pointer"
        onClick={() => {
          setNotify(true);
        }}
      >
        <BellIcon className="w-5 h-5 text-primary" />
      </div> */}

      <Menu as="div" className="relative" ref={dropdownRef}>
        <div>
          <Menu.Button className="" onClick={toggleDropdown}>
            <div>
              <div className="flex items-center gap-3  cursor-pointer">
                <div
                  className="w-[40px] h-[40px] flex items-center justify-center border border-green-500 bg-[#e7802028]   rounded-full shadow-lg relative"
                  style={{
                    backgroundImage: `url(${avatar})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <p className="absolute -top-1 right-0 w-[10px] h-[10px] rounded-full bg-[#219654e8] border border-white" />
                </div>
                <div className="text-green-600">
                  {isOpen ? (
                    <ArrowCircleUp
                      variant="Bold"
                      className="w-5 h-5 font-bold"
                    />
                  ) : (
                    <ArrowCircleDown
                      variant="Bold"
                      className="w-5 h-5 font-bold"
                    />
                  )}
                </div>
              </div>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute -left-[100px] top-[72px] z-10 mt-2 w-48  rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item as="div" className="p-4">
              <Link
                to="/account"
                className="text-primary flex items-center gap-3 mb-4 text-sm"
              >
                <User className="h-4 w-4" />
                <span>Account Info</span>
              </Link>
              <div
                onClick={() => {
                  showModal();
                  setIsOpen(!isOpen);
                }}
                className="text-primary flex items-center gap-3 text-sm cursor-pointer"
              >
                <Check className="h-4 w-4" />
                <span>Logout</span>
              </div>
            </Menu.Item>
          </Menu.Items>
        </Transition>
        {Modal({
          children: (
            <div>
              <LogoutPrompt
                cancel={showModal}
                logout={() => {
                  handleLogout();
                }}
              />
            </div>
          ),
          showCloseIcon: true,
          size: "sm",
          dismissOnclickOutside: true,
        })}
      </Menu>
      <Notifications open={notify} setOpen={setNotify} notifications={data} />
    </div>
  );
};
