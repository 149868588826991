import { useQuery } from "@tanstack/react-query";
import { organization } from "../../../services/organization/organization.service";

export const useProfile = () => {
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ["get-organization"],
    queryFn: () => organization.getOrganization(),
  });
  // const { data: profile } = useQuery({
  //   queryKey: ["get-profile"],
  //   queryFn: () => userService.getUserProfile(),
  // });
  return { data, isLoading, isFetching, refetch};
};
