import http from "../plugins/http";

class Permissions {
  async getPermissions() {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/organizations/settings/permissions?grouped=true`
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export const permissionsService = new Permissions();
