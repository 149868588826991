import { Bank, Location } from "iconsax-react";
import React from "react";
import { Greeting, Heading } from "../../../components";

export const HeaderDetails = ({ user }) => {
  return (
    <>
      <div>
        <div className="flex items-center  gap-5">
          <Heading>
            <div className="flex items-center gap-3">
              <div className="">
                <Greeting name={user ?? "UU"} />
              </div>
            </div>
          </Heading>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2 text-primary mt-1 ">
            <Bank variant="Bold" className="w-5 h-5 cursor-pointer" />
            <p className="text-gray-700">Organization representative</p>
          </div>
        </div>
      </div>
    </>
  );
};
