import { Link } from "react-router-dom";
import { Button, Input } from "../../../components";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

export const LoginForm = ({
  register,
  handleSubmit,
  errors,
  isPending,
  mutate,
}) => {
  const onSubmit = (data) => {
    const payload = {
      email: data?.email,
      password: data?.password,
    };
    mutate(payload);
  };
  return (
    <div>
      <form className=" space-y-9 mt-4" onSubmit={handleSubmit(onSubmit)}>
        <p>Hello, welcome back!</p>
        <Input
          label="Email address"
          placeholder="enter email..."
          id="email"
          borderNone={true}
          {...register("email", {
            required: true,
            pattern: /^\S+@\S+$/i,
          })}
          error={errors.email && "Email is required"}
        />
        <Input
          label="Password"
          type="password"
          placeholder="xxxxx"
          id="password"
          borderNone={true}
          {...register("password", { required: true })}
          error={errors.password && "Password is required"}
        />

        <Button type="submit" disabled={isPending}>
          <span className="flex items-center gap-3">
            Login <ArrowRightIcon className="w-5 h-5" />
          </span>
        </Button>
      </form>
    </div>
  );
};
