import { Arrow, ArrowLeft, ArrowLeft2 } from "iconsax-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const HandleGoBack = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        onClick={() => {
          navigate(-1);
        }}
        className="flex w-fit justify-end ml-auto items-center gap-3 font-semibold text-[14px] cursor-pointer px-3 py-1 border text-primary  border-[#e7802081] rounded-md"
      >
        <ArrowLeft className="w-4 h-4" />
      </div>
    </div>
  );
};
