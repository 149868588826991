import React from "react";
import {
  Card,
  Container,
  Header,
  Heading,
  Label,
  Select,
  SubHeading,
} from "../../components";
import Chart from "../../components/Charts/Chart";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { formatNaira } from "../../utils";
import { Calendar2 } from "iconsax-react";
import { cardData } from "./component/MockData";

export const Report = ({ data, isLoading }) => {
  const [year, setYear] = useState(new Date());
  const { control, watch, register } = useForm();
  const type = watch("chart", "line")?.value;
  const options = [
    {
      label: "Line",
      value: "line",
    },
    {
      label: "Bar",
      value: "bar",
    },
    {
      label: "Area",
      value: "area",
    },
  ];
  return (
    <div className="lg:mt-0 mt-8">
      <Header>
        <Heading>Report</Heading>
        <p className="mt-1">A display of your analytic daily reports.</p>
      </Header>

      <div className="grid grid-cols-12 gap-7 mb-8">
        {cardData.map((item) => (
          <div className="lg:col-span-3 col-span-12">
            <Card card={item} key={item?.id} />
          </div>
        ))}
      </div>
      <div>
        <p className="mb-3 text-[18px] font-medium">Available balance</p>
        <h1 className="text-[21px] font-bold text-gray-800">
          {isLoading || data?.avail_bal === undefined
            ? "₦0.00"
            : formatNaira(data?.avail_bal)}
        </h1>
      </div>

      <div className="flex items-center justify-end mb-5">
        <div className=" flex items-center gap-8">
          <div className="w-[120px] relative">
            <Calendar2
              variant="Bold"
              className="w-4 absolute top-8 right-3 z-20 text-primary"
            />
            <div className="mb-1">
              <Label label="Filter by Year" />
            </div>
            <DatePicker
              selected={year}
              showMonthDropdown
              onChange={setYear}
              showYearPicker
              dateFormat="yyyy"
              className="block w-full appearance-none  border border-[#e780206b] px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#e780206b] focus:outline-none focus:ring-[#e780206b] sm:text-sm disabled:cursor-not-allowed disabled:opacity-70 rounded-[30px]"
            />
          </div>
        </div>
      </div>
      <Chart />
    </div>
  );
};
