import { Button, Select, Input } from "../../../../components";

export const FormStep4 = ({
  register,
  errors,
  control,
  handleSubmit,
  handlePrev,
  isPending,
  mutate,
  currentStep,
}) => {
  const onSubmit = (data) => {
    const payload = {
      transaction_tracking_ref: data?.transaction_tracking_ref,
      account_opening_tracking_ref: "string",
      product_code: data?.product_code,
      last_name: data?.last_name,
      customer_id: data?.customer_id,
      account_reference_number: data?.account_reference_number,
      other_names: data?.other_names,
      account_name: data?.account_name,
      full_name: data?.full_name,
      gender: 0,
      place_of_birth: data?.place_of_birth,
      date_of_birth: new Date(data?.date_of_birth).toISOString(),
      address: data?.address,
      national_identity_no: data?.national_identity_no,
      next_of_kin_phone_no: data?.next_of_kin_phone_no,
      next_of_kin_name: data?.next_of_kin_name,
      referral_phone_no: data?.referral_phone_no,
      referral_name: data?.referral_name,
      has_sufficient_info_on_account_info:
        data?.has_sufficient_info_on_account_info?.value,
      account_information_source: 0,
      other_account_information_source: data?.other_account_information_source,
      account_officer_code: data?.account_officer_code,
      account_number: data?.account_number,
      notification_preference: data?.notification_preference?.value,
      transaction_permission: data?.transaction_permission?.value,
      account_tier: data?.account_tier?.value,
      admin: {
        first_name: data?.admin_first_name,
        last_name: data?.admin_last_name,
        email: data?.admin_email,
        phone_number: data?.admin_phone,
        gender: data?.admin_gender?.value,
      },
    };
    mutate(payload);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="flex items-center justify-between pt-3">
        <p className="font-bold text-lg">Admin Info</p>
        <p>Step {`${currentStep}/4`}</p>
      </div>
      <Input
        label="First name"
        id="admin_first_name"
        {...register("admin_first_name", { required: true })}
        error={errors.admin_first_name && "First name is required"}
      />

      <Input
        label="Last name"
        id="admin_last_name"
        {...register("admin_last_name", { required: true })}
        error={errors.admin_last_name && "Last name is required"}
      />

      <Input
        label="Phone number"
        id="admin_phone"
        placeholder="phone number must begin with 0 eg:(070)"
        {...register("admin_phone", {
          required: true,
          maxLength: 11,
          minLength: 11,
        })}
        error={
          errors.admin_phone && "Phone number is required and must be 11 digit"
        }
      />

      <Input
        label="Email"
        id="admin_email"
        {...register("admin_email", { required: true, pattern: /^\S+@\S+$/i })}
        error={errors.admin_email && "Email is required"}
      />
      <Select
        label="Gender"
        name="admin_gender"
        control={control}
        options={[
          { label: "Male", value: "male" },
          { label: "Female", value: "female" },
        ]}
        error={errors.admin_gender && "Gender are required"}
      />

      <div className="flex items-center justify-between pt-4">
        <Button type="button" variant="outline" onClick={handlePrev}>
          Back
        </Button>
        <Button type="submit" disabled={isPending}>
          Submit
        </Button>
      </div>
    </form>
  );
};
