import { useForm } from "react-hook-form";
import { AuthWrapper } from "../../../components";
import { LoginForm } from "../Login/LoginForm";
import { useState } from "react";
import { authService } from "../../../services/auth/auth.service";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useHandleNextStep } from "../../../hooks/useHandleNextStep";
import { TwoFA } from "./TwoFA";
import { useStore } from "../../../store/useStore";
export const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const setUsername = useStore((state) => state.setUsername);
  const { handleNextStep, handlePreviousStep, currentStep } =
    useHandleNextStep(2);
  const [securityData, setSecurityData] = useState({});
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => authService.preLogin(data),
    onSuccess: (data) => {
      localStorage.setItem(
        "user",
        `${data?.user?.first_name} ${data?.user?.last_name}`
      );
      useStore.setState({
        user: localStorage.getItem("user"),
      });
      if (data["2fa_token"]) {
        setSecurityData(data);
        handleNextStep();
      } else {
        navigate("/dashboard");
      }
    },
  });
  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <LoginForm
            handleSubmit={handleSubmit}
            errors={errors}
            mutate={mutate}
            register={register}
            isPending={isPending}
          />
        );
      case 2:
        return (
          <TwoFA handlePrev={handlePreviousStep} payloadData={securityData} />
        );

      default:
        return null;
    }
  };
  let title = "";
  let subtitle = "";
  switch (currentStep) {
    case 1:
      title = "Login to your account.";
      subtitle = "Login to see your personal Dashboard";
      break;
    case 2:
      title = "Two-Factor Aunthentication";
      subtitle =
        "Answer your security question to gain access to your personal dashoard";
      break;
  }
  return (
    <AuthWrapper showInstruction title={title} subtitle={subtitle}>
      {renderFormStep()}
    </AuthWrapper>
  );
};
