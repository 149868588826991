import React from "react";
import {
  Button,
  FileUpload,
  Header,
  Heading,
  Input,
  SubHeading,
} from "../../../../components";
import Avatar from "../../../../assets/img/plus.png";
import { useConvertFileToJson, useModal } from "../../../../hooks";
import { Discard } from "./Discard";
import { useNavigate } from "react-router-dom";
import { UserPlusIcon } from "@heroicons/react/24/outline";
export const ProfileUpdate = ({ file }) => {
  const { Modal, showModal } = useModal();
  const navigate = useNavigate();
  return (
    <div>
      <Header>
        <Heading>Account Settings/edit-profile</Heading>
      </Header>
      <div className="pb-5 w-[600px]">
        <SubHeading>Edit Profile</SubHeading>
        <div>
          <p className="text-gray-500 mt-1 text-[15px]">
            Update your personal details
          </p>
        </div>
        <div className="border-b border-gray-200 mt-6"></div>
        <form className="pr-[90px]">
          <div className=" grid grid-cols-12 mt-6 gap-10">
            <div className="col-span-12  grid grid-cols-2 gap-4">
              <Input label="First Name" placeholder="First name" />

              <Input label="Last Name" placeholder="Last name" />
            </div>

            <div className="col-span-12">
              <Input label="Email Address" placeholder="Email Address" />
            </div>

            <div className="col-span-12">
              <Input
                label="Phone Number"
                placeholder="Enter new phone number"
              />
            </div>
          </div>
          <div className="border-b border-gray-200 mt-6"></div>
          <div className="mt-4 flex  gap-4 items-center">
            <div className="flex gap-5">
              <div>
                {file ? (
                  <img
                    src={file ? URL.createObjectURL(file) : Avatar}
                    className="w-[70px]"
                  />
                ) : (
                  <div className="bg-primary w-[70px] h-[70px] rounded-full flex justify-center items-center">
                    <UserPlusIcon className="w-10 h-10 text-white" />
                  </div>
                )}
              </div>
            </div>
            <div>
              <p className="text-[15px] text-gray-500 mt-1">
                Upload a png, jpg or jpeg max(1MB).
              </p>
            </div>
          </div>
          <div className="col-span-12 mt-5">
            <Input
              label="Enter Password"
              type="password"
              placeholder="XXXXXX"
            />
          </div>
          <div className=" flex items-center gap-5 justify-end mt-7">
            <Button type="submit">Save</Button>
            <Button onClick={showModal} variant="outline" type="button">
              Discard
            </Button>
          </div>
        </form>
      </div>
      {Modal({
        children: (
          <div className="">
            <Discard showModal={showModal} navigate={navigate} />
          </div>
        ),
        showCloseIcon: false,
        size: "sm",
      })}
    </div>
  );
};
