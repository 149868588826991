import { Button, Input, Select } from "../../../../../components";
import { parse, differenceInYears } from "date-fns";
export const FormStep2 = ({
  errors,
  control,
  register,
  handleNext,
  handleSubmit,
  currentStep,
}) => {
  const today = new Date();

  const validateDateOfBirth = (dateString) => {
    const selectedDate = parse(dateString, "yyyy-MM-dd", new Date());
    const age = differenceInYears(today, selectedDate);
    return age >= 18;
  };

  const onSubmit = () => {
    handleNext();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="flex items-center justify-between pt-3">
        <p className="font-bold text-lg">Customer Info</p>
        <p>Step {`${currentStep}/4`}</p>
      </div>
      <Input
        label="Full name"
        id="full_name"
        {...register("full_name", { required: true })}
        error={errors.full_name && "Full name is required"}
      />
      <Input
        label="Last name"
        id="last_name"
        {...register("last_name", { required: true })}
        error={errors.last_name && "Last name is required"}
      />
      <Input
        label="Other names"
        id="other_names"
        {...register("other_names", { required: true })}
        error={errors.other_names && "Other names is required"}
      />
      <Input
        label="Account number"
        id="account_number"
        {...register("account_number", { required: true })}
        error={errors.account_number && "account_number is required"}
      />
      <Input
        label="Account name"
        id="account_name"
        {...register("account_name", { required: true })}
        error={errors.account_name && "account_name is required"}
      />

      <Input
        label="Place of birth"
        id="place_of_birth"
        {...register("place_of_birth", { required: true })}
        error={errors.place_of_birth && "Place of birth is required"}
      />
      <Input
        label="National identity number"
        id="national_identity_no"
        {...register("national_identity_no", { required: true })}
        error={
          errors.national_identity_no && "National identity number is required"
        }
      />

      <Select
        label="Gender"
        name="gender"
        control={control}
        options={[
          { label: "Male", value: "male" },
          { label: "Female", value: "female" },
        ]}
        error={errors.gender && "Gender is required"}
      />
      <Input
        label="Address"
        id="address"
        {...register("address", { required: true })}
        error={errors.address && "Address is required"}
      />

      <Input
        label="D.O.B"
        type="date"
        id="date_of_birth"
        {...register("date_of_birth", {
          required: true,
          validate: validateDateOfBirth,
        })}
        error={errors.date_of_birth && "DOB is required or age is less than 18"}
      />

      <Input
        label="Next of kin phone number"
        id="next_of_kin_phone_no"
        {...register("next_of_kin_phone_no", { required: true })}
        error={
          errors.next_of_kin_phone_no && "Next of kin phone number is required"
        }
      />

      <Input
        label="Next of kin name"
        id="next_of_kin_name"
        {...register("next_of_kin_name", { required: true })}
        error={errors.next_of_kin_name && "Next of kin name is required"}
      />
      <div className=" justify-end flex">
        <Button type="submit">Continue</Button>
      </div>
    </form>
  );
};
