import http from "../plugins/http";
import { notifyError, notifySuccess } from "../utils/toast";

class TransactionService {
  async getAllTransfers(params) {
    try {
      const response = await http.get(`${process.env.REACT_APP_CONSOLE_URL}/back-office/transaction/transfer-requests`, {
        params,
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getTransactionHistory(params) {
    try {
      const response = await http.get(`${process.env.REACT_APP_CONSOLE_URL}/back-office/transaction/history`, {
        params,
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getTransactionByAccountId(account_id) {
    try {
      const response = await http.get(
        `/get_transaction_by_account_id/${account_id}`
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getAccountLookup(params) {
    try {
      const response = await http.get(`/transaction/account-lookup`, {
        params,
      });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getAccountLookupNoBankcode(params) {
    try {
      const response = await http.get(
        `/transaction/account-lookup-no-bankcode`,
        {
          params,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async handleDecision(payload, decision) {
    try {
      const response = await http.put(
        `/transaction/transfer-request-decisions/${decision}`,
        payload
      );
      notifySuccess(response?.message);
      return response.data;
    } catch (error) {
      notifyError(error?.response?.data?.message);
      throw new Error(error);
    }
  }
  async getDecisions(params) {
    try {
      const response = await http.get(
        `/transaction/transfer-request-decisions`,
        { params }
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export const transactionService = new TransactionService();
