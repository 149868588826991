import { Colorfilter, Key } from "iconsax-react";

export const data = [
  {
    title: "Security",
    to: "/settings/security",
    icon: Key,
  },
  {
    title: "Roles & Permission",
    to: "/settings/roles",
    icon: Key,
  },
  // {
  //   title: "Themes",
  //   to: "/settings/themes",
  //   icon: Colorfilter,
  // },
];
