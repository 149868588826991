/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { accountService } from "../../../services/accounts/account.service";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useHandleNextStep } from "../../../hooks/useHandleNextStep";
import { FormStep2 } from "./FormSteps/FormStep2";
import { FormStep1 } from "./FormSteps/FormStep1";
import { FormStep3 } from "./FormSteps/FormStep3";
import { FormStep4 } from "./FormSteps/FormStep4";

const formStateOptions = {
  accountVerification: "account-verification",
  adminDetails: "admin-details",
};

export const RetailAccountForm = ({ setIshowing }) => {
  const navigate = useNavigate();
  const [accountNumber, setAccountNumber] = useState("");
  const [accountInfo, setAccountInfo] = useState([]);
  const debouncedValue = useDebounce(accountNumber, 800);
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState(
    formStateOptions.accountVerification
  );
  const { currentStep, handlePreviousStep, handleNextStep, setCurrentStep } =
    useHandleNextStep(5);
  const [accountLookupError, setAccountLookError] = useState(null);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => accountService.onboardSingleAccountRetail(data),
    onSuccess: () => {
      navigate("/accounts");
    },
  });

  const renderForm = () => {
    switch (currentStep) {
      case 1:
        return (
          <FormStep2
            control={control}
            errors={errors}
            register={register}
            handleSubmit={handleSubmit}
            handleNext={handleNextStep}
            currentStep={currentStep}
          />
        );

      case 2:
        return (
          <FormStep1
            errors={errors}
            register={register}
            handleSubmit={handleSubmit}
            handleNext={handleNextStep}
            handlePrev={handlePreviousStep}
            control={control}
            currentStep={currentStep}
          />
        );
      case 3:
        return (
          <FormStep3
            errors={errors}
            register={register}
            handleSubmit={handleSubmit}
            handleNext={handleNextStep}
            control={control}
            handlePrev={handlePreviousStep}
            currentStep={currentStep}
          />
        );
      case 4:
        return (
          <FormStep4
            errors={errors}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            isPending={isPending}
            mutate={mutate}
            handlePrev={handlePreviousStep}
            currentStep={currentStep}
          />
        );
      default:
        return null;
    }
  };

  // const user_Id = localStorage.getItem("user_Id");
  // const onSubmit = async (data) => {
  //   const payload = {
  //     account_name: data?.account_name,
  //     account_number: data?.account_number,
  //     type: data?.type?.value,
  //     created_by: user_Id,
  //     admin: {
  //       first_name: data?.first_name,
  //       last_name: data?.last_name,
  //       email: data?.email,
  //       phone_number: data?.phone,
  //       gender: data?.gender?.value,
  //     },
  //   };
  //   mutate(payload);
  // };

  return <div>{renderForm()}</div>;
};
