import React from "react";
import { ApiWrapper } from "../components/ApiWrapper";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
import { ApiTable } from "./ApiTable";
import { ApiData } from "../components/MockData";
import Entries from "../../../components/Filter/Entries";
import { Container, Pagination } from "../../../components";
import { usePagination, useTableSerialNumber } from "../../../hooks";
import { TabFilter } from "../../Dashboard/component/TabFilter";

export const Api = () => {
  const { paginatedData, page, setPage } = usePagination(ApiData);
  const initialSerialNumber = useTableSerialNumber(page);
  return (
    <ApiWrapper>
      <div className="mt-7 flex flex-col gap-5">
        <TabFilter />
        <ApiTable
          data={paginatedData}
          initialSerialNumber={initialSerialNumber}
        />
        {ApiData.length !== 5 && (
          <div className="flex items-center justify-center mt-4">
            <Pagination
              totalItems={ApiData.length}
              currentPage={page}
              handlePageClick={setPage}
            />
          </div>
        )}
      </div>
    </ApiWrapper>
  );
};
