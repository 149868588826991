import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  ExportButton,
  Header,
  Heading,
} from "../../../components";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
import { formatDate, formatNaira } from "../../../utils";
import { RenderData } from "./components/RenderData";
import { ArrowLeft} from "iconsax-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { loanService } from "../../../services/loan/loan.service";
import ContentLoader from "react-content-loader";
import { useConvertFileToJson } from "../../../hooks";

export const LoanRepaymentSchedule = () => {
  const { id } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ["get-loan-repayment"],
    queryFn: () => loanService.getLoanRepayment(id),
  });
  const navigate = useNavigate();
  const { convertJsonToExcel } = useConvertFileToJson("repayment schedule");
  const { isPending: isDownloadingRepayment, mutate: downloadRepayment } =
    useMutation({
      mutationKey: ["loan-repayment"],
      mutationFn: () => loanService.getLoanRepayment(id),
      onSuccess: (data) => {
        const repayment = data?.map((dat) => {
          return {
            repayment_amount: formatNaira(dat?.amount),
            repayment_date: formatDate(dat?.repayment_date),
            retries: dat?.retries,
            time_created: formatDate(dat?.created_at),
            loan_amount: formatNaira(dat?.loan_application?.loan_amount),
            total_repayment_amount: formatNaira(
              dat?.loan_application?.repayment_amount
            ),
            interest: formatNaira(dat?.loan_application?.interest),
            status: dat?.status,
          };
        });

        convertJsonToExcel(repayment);
      },
    });

  return (
    <div>
      {" "}
      <Header>
        <div className="flex flex-col lg:flex-row lg:items-center items-start ml-2 lg:ml-0">
          <div className="flex items-center gap-6">
            <ArrowLeft
              onClick={() => {
                navigate(-1);
              }}
              variant="Bold"
              className="text-primary cursor-pointer"
            />
            <Heading>Loan Repayment</Heading>
          </div>
          <SearchFilter
            placeholder={"Search by loan repayment......"}
            noBorder={true}
          />
        </div>
      </Header>
      <div className="flex justify-between items-center my-4">
        <p className="font-bold">List of your loan repayment schedule</p>
        {data?.length !== 0 && (
          <ExportButton
            onClick={downloadRepayment}
            disabled={isDownloadingRepayment}
          >
            Export loan reepayment schedule
          </ExportButton>
        )}
      </div>
      {isFetching ? (
        <ContentLoader viewBox="0 0 380 70">
          <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
        </ContentLoader>
      ) : (
        <div>
          {" "}
          <RenderData data={data} />
        </div>
      )}
    </div>
  );
};
