import { useEffect, useState } from "react";
import { limit } from "../constants/limit";
export const useTableSerialNumber = (page, perPage = limit) => {
  const [initialSerialNumber, setInitialSerialNumber] = useState(1);

  useEffect(() => {
    setInitialSerialNumber((page - 1) * perPage + 1);
  }, [page, perPage]);

  return initialSerialNumber;
};
