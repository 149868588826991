import React, { useState} from "react";
import { Button, Checkbox, Input, Select } from "../../../../components";
import { AddCircle } from "iconsax-react";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { useRoles } from "../../../Settings/Roles/hook/useRoles";
import { useProfile } from "../../../Account/hooks/useAccount";
import { useMutation } from "@tanstack/react-query";
import { loanService } from "../../../../services/loan/loan.service";

export const StepTwo = ({
  currentStep,
  handlePrev,
  control,
  watch,
  register,
  errors,
  navigate,
  handleSubmit,
  setValue,
}) => {
  const [selectFields, setSelectFields] = useState([]);
  const { data } = useProfile();
  const { data: roles } = useRoles();
  console.log(roles);
  const account_id = "";
  const organization_id = data?.organization?.organization_id;


  const addSelectField = () => {
    const newId = selectFields.length + 1;
    setSelectFields([
      ...selectFields,
      { id: newId, inputType: "", label: "", required: false, options: [] },
    ]);
  };

  const removeSelectField = (index) => {
    const updatedFields = selectFields.filter((_, i) => i !== index);
    setSelectFields(updatedFields);
  };
  const { mutate, isPending } = useMutation({
    mutationKey: ["create-loan-product"],
    mutationFn: (data) => loanService.createLoanProduct(data),
    onSuccess: () => {
      navigate("/loan-products");
    },
  });
  const onSubmit = (data) => {
    const payload = {
      name: data?.name,
      description: data?.details,
      requirements: selectFields?.map((field, index) => {
        if (watch(`inputType_${index + 1}`)?.value === "select") {
          return {
            inputType: watch(`inputType_${index + 1}`)?.value,
            label: watch(`label_${index + 1}`),
            required: watch(`required_${index + 1}`) ?? false,
            options: field.options,
          };
        }
        if (watch(`inputType_${index + 1}`)?.value === "radio") {
          return {
            inputType: watch(`inputType_${index + 1}`)?.value,
            label: watch(`label_${index + 1}`),
            required: watch(`required_${index + 1}`) ?? false,
            options: field.options,
          };
        }
        if (watch(`inputType_${index + 1}`)?.value === "file") {
          return {
            inputType: watch(`inputType_${index + 1}`)?.value,
            label: watch(`label_${index + 1}`),
            required: watch(`required_${index + 1}`) ?? false,
            fileType: watch(`fileType_${index + 1}`),
          };
        } else {
          return {
            inputType: watch(`inputType_${index + 1}`)?.value,
            label: watch(`label_${index + 1}`),
            required: watch(`required_${index + 1}`) ?? false,
          };
        }
      }),
      interest_rate: data?.interest,
      max_loan_amount: parseFloat(data?.max_loan_amount),
      min_loan_amount: parseFloat(data?.min_loan_amount),
      max_tenor: parseInt(data?.max_loan_tenor),
      min_tenor: parseInt(data?.min_loan_tenor),
      tenor_type: data?.tenor_type?.value,
      allow_multi_tenor: data?.is_multi || false,
      account_id: account_id,
      organization_id: organization_id,
      created_by: "123499",
      approval: {
        role_id: data?.role_authoriser?.value,
        minimum_no_of_approvals: data?.minimun_number_of_authorization_approval,
      },
      disbursement: {
        role_id: data?.role?.value,
        account_id:data?.disbursement_account_id?.value,
      },
      repayment:{
        account_id:data?.repayment_account_id?.value
      }
    };
    mutate(payload);
  };

  const options = [
    { label: "Text", value: "text" },
    { label: "Email", value: "email" },
    { label: "Select", value: "select" },
    { label: "TextArea", value: "textarea" },
    { label: "Checkbox", value: "checkbox" },
    { label: "File", value: "file" },
    { label: "Number", value: "number" },
    { label: "Date", value: "date" },
    { label: "Option button", value: "radio" },
  ];
  const fileExtensions = [
    { label: "Doc", value: ".doc" },
    { label: "Docx", value: ".docx" },
    { label: "PDF", value: ".pdf" },
    { label: "Plain Text", value: ".txt" },
    { label: "Excel", value: ".xls" },
    { label: "JPEG", value: ".jpg" },
    { label: "PNG", value: ".png" },
    { label: "MP4", value: ".mp4" },
  ];

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="font-semibold">Add a New Product</h1>
        <span>Step {currentStep}/2</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 mt-6">
        {selectFields?.map((field, index) => (
          <div key={field.id} className="space-y-5">
            <Select
              control={control}
              name={`inputType_${index + 1}`}
              placeholder="Select Input Type"
              label="Input Type"
              options={options}
              error={
                errors[`inputType_${index + 1}`] && "Input type is required"
              }
            />

            <Input
              label="Label"
              type="text"
              id={`label_${index + 1}`}
              {...register(`label_${index + 1}`, { required: true })}
              error={errors[`label_${index + 1}`] && "Label is required"}
              placeholder="Label"
            />
            {watch(`inputType_${index + 1}`)?.value === "file" && (
              <Select
                control={control}
                name={`fileType_${index + 1}`}
                placeholder="Select file Type"
                label="File Type"
                isMulti
                options={fileExtensions}
                error={
                  errors[`fileType_${index + 1}`] && "File type is required"
                }
              />
            )}
            {watch(`inputType_${index + 1}`)?.value === "radio" && (
              <div>
                <Input
                  id={`inputOption_${index + 1}`}
                  placeholder="Options for select"
                  label="Options"
                  {...register(`inputOption_${index + 1}`)}
                />
                <span
                  className="cursor-pointer text-sm text-right block mt-1 text-primary"
                  onClick={() => {
                    const optionValue = watch(`inputOption_${index + 1}`);

                    if (optionValue) {
                      setSelectFields((prevFields) => {
                        const updatedFields = prevFields.map((field, i) => {
                          if (i === index) {
                            return {
                              ...field,
                              options: [
                                ...field.options,
                                {
                                  label: optionValue,
                                  value: optionValue.toLowerCase(),
                                },
                              ],
                            };
                          }
                          return field;
                        });

                        // Clear the input field
                        setValue(`inputOption_${index + 1}`, "");

                        return updatedFields;
                      });
                    }
                  }}
                >
                  Add Option
                </span>

                <div className="flex items-center gap-2">
                  {field.options.map((option, optionIndex) => (
                    <div
                      key={`${index}_${optionIndex}`}
                      className="flex items-center mt-1"
                    >
                      <div className="flex items-center gap-2 border px-[4px] py-[2px] rounded-lg border-primary">
                        <span className="text-[11px] text-gray-600">
                          {option.label}
                        </span>
                        <XCircleIcon
                          className="w-4 h-4 text-red-500 cursor-pointer"
                          onClick={() => {
                            setSelectFields((prevFields) => {
                              const updatedFields = prevFields.map(
                                (field, i) => {
                                  if (i === index) {
                                    return {
                                      ...field,
                                      options: field.options.filter(
                                        (_, j) => j !== optionIndex
                                      ),
                                    };
                                  }
                                  return field;
                                }
                              );
                              return updatedFields;
                            });
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {watch(`inputType_${index + 1}`)?.value === "select" && (
              <div>
                <Input
                  id={`inputOption_${index + 1}`}
                  placeholder="Options for select"
                  label="Options"
                  {...register(`inputOption_${index + 1}`)}
                />
                <span
                  className="cursor-pointer text-sm text-right block mt-1 text-primary"
                  onClick={() => {
                    const optionValue = watch(`inputOption_${index + 1}`);

                    if (optionValue) {
                      setSelectFields((prevFields) => {
                        const updatedFields = prevFields.map((field, i) => {
                          if (i === index) {
                            return {
                              ...field,
                              options: [
                                ...field.options,
                                {
                                  label: optionValue,
                                  value: optionValue.toLowerCase(),
                                },
                              ],
                            };
                          }
                          return field;
                        });

                        // Clear the input field
                        setValue(`inputOption_${index + 1}`, "");

                        return updatedFields;
                      });
                    }
                  }}
                >
                  Add Option
                </span>

                <div className="flex items-center gap-2">
                  {field.options.map((option, optionIndex) => (
                    <div
                      key={`${index}_${optionIndex}`}
                      className="flex items-center mt-1"
                    >
                      <div className="flex items-center gap-2 border px-[4px] py-[2px] rounded-lg border-primary">
                        <span className="text-[11px] text-gray-600">
                          {option.label}
                        </span>
                        <XCircleIcon
                          className="w-4 h-4 text-red-500 cursor-pointer"
                          onClick={() => {
                            setSelectFields((prevFields) => {
                              const updatedFields = prevFields.map(
                                (field, i) => {
                                  if (i === index) {
                                    return {
                                      ...field,
                                      options: field.options.filter(
                                        (_, j) => j !== optionIndex
                                      ),
                                    };
                                  }
                                  return field;
                                }
                              );
                              return updatedFields;
                            });
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flex items-center justify-between">
              <Checkbox
                title="Required"
                id={`required_${index + 1}`}
                checked={field.required}
                {...register(`required_${index + 1}`, { required: false })}
                onChange={(e) => {
                  setValue(`required_${index + 1}`, e.target.checked);
                }}
              />

              <Button
                type="button"
                variant={"danger"}
                onClick={() => removeSelectField(index)}
                className="text-red-500"
              >
                Remove
              </Button>
            </div>
          </div>
        ))}
        <div className="flex justify-between items-center">
          <div
            className="flex items-center gap-3 font-[500] cursor-pointer"
            onClick={addSelectField}
          >
            <AddCircle variant="Bold" className="w-5 h-5 text-primary" />
            <span className="text-primary">Add another field</span>
          </div>
          <Button type="button" onClick={handlePrev} variant="outline">
            Go back
          </Button>
        </div>
        <Button type="submit" disabled={isPending} isFullWidth>
          Create product
        </Button>
      </form>
    </div>
  );
};
