import { useMutation } from "@tanstack/react-query";
import { Button, SubHeading, TextArea } from "../../../../components";
import { loanService } from "../../../../services/loan/loan.service";
import { useState } from "react";

export const LoanDisbursement = ({ refetch, showModal }) => {
  const [reason, setReason] = useState("");
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => loanService.LoanDisburseApproval(data),
    onSuccess: () => {
      showModal();
      refetch();
    },
  });
  const { mutate: reject, isPending: isLoading } = useMutation({
    mutationFn: (data) => loanService.LoanDisburseRejection(data),
    onSuccess: () => {
      showModal();
      refetch();
    },
  });

  return (
    <div className="space-y-5">
      <SubHeading>Loan Disbursement</SubHeading>
      <p className="text-sm">
        Note: this action cannot be reversed so carefully review to avoid
        mistakes
      </p>
      <TextArea
        onChange={(e) => {
          setReason(e.target.value);
        }}
        label="Reason"
        placeholder="Enter reason...."
      />
      <div className="flex justify-end gap-5 items-center">
        <Button
          onClick={() => {
            mutate({
              reason: reason,
            });
          }}
          disabled={isPending}
          variant="success"
        >
          Approve Disbursal
        </Button>
        <Button
          onClick={() => {
            reject({
              reason: reason,
            });
          }}
          disabled={isLoading}
          variant="danger"
        >
          Reject Disbursal
        </Button>
      </div>
    </div>
  );
};
