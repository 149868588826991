import { Badge, Header, Heading } from "../../../components";
import { Outlet, NavLink, useParams} from "react-router-dom";
import { useCallback } from "react";
import { Card, User } from "iconsax-react";
import { useQuery } from "@tanstack/react-query";
import { accountService } from "../../../services/accounts/account.service";

const secondaryNavigation = [
  { name: "Overview", href: "overview", current: true },
  { name: "Users", href: "users", current: false },
  { name: "Transfer Request", href: "transfer-requests", current: false },
  { name: "Mandate Rules", href: "mandate-rule", current: false },
];

export const AccountOverview = ({details}) => {
  const { id: accountId } = useParams();
  const getAccountStatus = useCallback((isDisabled) => {
    return isDisabled ? "disabled" : "active";
  }, []);
const {data}=useQuery({
  queryKey:["account-id", accountId],
  queryFn:()=>accountService.getAccountByID({account_id:accountId}),
  enabled:!!accountId
})
  return (
    <div>
      <Header>
        <Heading>Account Overview</Heading>
        <div className="text-gray-900  capitalize break-words">
          <p className="mt-1">
            {data?.account_name ?? "..."}
          </p>
        </div>
      </Header>
      <div className="min-h-screen bg-white">
        <div className="ml-2">
          <nav className="flex  h items-center justify-between">
            <ul className="flex gap-10 flex-none  px-4 text-sm font-medium leading-6 text-gray-500">
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <NavLink
                    to={`/accounts/${accountId}/${item.href}`}
                    className={({ isActive }) =>
                      isActive
                        ? "text-gray-800 border-b-[2px] border-primary font-semibold  py-3"
                        : ""
                    }
                  >
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
            {/* <div>
              <p className="text-[14px]">
                <Badge status={"active"}>Active</Badge>
              </p>
            </div> */}
          </nav>
          <div className="border-gray-200 border-b  pb-4 pt-10 space-y-6">
            <div className="flex items-center gap-10">
              <div>
                <p className="text-sm font-medium text-gray-500 mb-1 flex flex-col">
                  <Card className="w-5 text-primary" />
                  Account Number
                </p>
                <h2 className="text-sm font-semibold text-gray-900">
                  {data?.account_number??"..."}
                </h2>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500 mb-1">
                  <User className="w-5 text-primary" />
                  Customer ID
                </p>
                <div className="flex items-center">
                  <div className="pr-3 text-sm">
                    <div className="text-gray-900 font-semibold capitalize break-words">
                     {data?.customer_id??"..."}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500 mb-1 flex flex-col">
                  <User className="w-5 text-primary" />
                  Account Name
                </p>
                <h2 className="text-sm font-semibold text-gray-900">
                  {data?.account_name??"..."}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};
