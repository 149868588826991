import { useState } from "react";
import { useColors } from "../../../hooks/useColors";
import { Button, FileUpload, SubHeading } from "../../../components";
import { CloudArrowUpIcon } from "@heroicons/react/20/solid";
import Picker from "../../../components/ColorPicker/ColorPicker";

export const Themes = () => {
  const {
    primaryColor,
    secondaryColor,
    setPrimaryColor,
    setSecondaryColor,
    borderColor,
    setBorderColor,
    lightColor,
    setLightColor,
    resetColors,
    handleLogoChange,
    handleRemoveLogo,
    logo,
  } = useColors();
  const [hex, setHex] = useState();
  const [isColorPickerVisible, setColorPickerVisibility] = useState(null);
  const colors = [
    {
      label: "Primary",
      value: primaryColor,
      action: (newHex) => {
        setPrimaryColor(newHex);
      },
    },
    {
      label: "Secondary",
      value: secondaryColor,
      action: (newHex) => {
        setSecondaryColor(newHex);
      },
    },
    {
      label: "Border",
      value: borderColor,
      action: (newHex) => {
        setBorderColor(newHex);
      },
    },
    {
      label: "Light Border",
      value: lightColor,
      action: (newHex) => {
        setLightColor(newHex);
      },
    },
  ];
  return (
    <div className="mt-8">
      <SubHeading>Change Available Colors</SubHeading>

      {colors?.map((theme, i) => (
        <div key={i} className="flex gap-3 mt-10 border-border relative">
          {isColorPickerVisible === i && (
            <div className="absolute top-10 z-10">
              <Picker
                hex={hex || theme.value}
                onChange={(color) => {
                  setHex(color.hex);
                  theme.action(color.hex);
                }}
              />
            </div>
          )}

          <span
            onClick={() =>
              setColorPickerVisibility((prevIndex) =>
                prevIndex === i ? null : i
              )
            }
            className="italic cursor-pointer flex items-center gap-4"
          >
            <span
              className="w-[30px] h-[30px] block rounded-full border border-black"
              style={{
                backgroundColor: theme.value,
              }}
            ></span>
            {theme.label}
          </span>
        </div>
      ))}

      <div className="mt-8 flex items-center w-[500px]">
        <FileUpload
          width="w-[490px] lg:w-[400px] ml-[-10px] md:ml-0 lg:ml-0"
          label="Upload Logo"
          infoText="Drag and drop your logo here or click to browse"
          id="logoInput"
          onChange={handleLogoChange}
          removeFile={handleRemoveLogo}
          accept="image/*"
          Icon={() =>
            logo ? (
              <img src={logo} alt="Logo Preview" className="w-16" />
            ) : (
              <CloudArrowUpIcon className="w-16 h-16" />
            )
          }
        ></FileUpload>
      </div>
      <div className="my-6">
        <Button onClick={resetColors}>Reset To Default</Button>
      </div>
    </div>
  );
};
