import { useQuery } from "@tanstack/react-query";
import { roleService } from "../../../../services/role.service";

export const useRoles = (id) => {
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ["get-roles"],
    queryFn: () => roleService.getRoles(),
  });
  const { data:role} = useQuery({
    queryKey: ["get-role", id],
    queryFn: () => roleService.roleById(id),
    enabled:!!id
  });
  return { data, isLoading, isFetching, refetch, role };
};
