import { forwardRef, useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { Label } from "../Label/Label";
import ErrorMessage from "../Error/ErrorMessage";

export const Input = forwardRef((props, ref) => {
  const {
    type = "text",
    name,
    id,
    placeholder,
    onChange,
    label,
    error,
    value,
    disabled,
    borderNone = false,
    setIcon = false,
    Icon = EnvelopeIcon,
  } = props;
  const [inputType, setInputType] = useState(type);

  return (
    <div className="relative">
      {label && <Label id={id} label={label} />}
      <div className="mt-1">
        <input
          id={id}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          ref={ref}
          type={inputType}
          className={
            borderNone === true
              ? "border-0 border-b outline-0 w-full border-primary transparent focus:border-b-primary focus:border-b-[2px]  appearance-none"
              : "block w-full appearance-none  border border-[#e7802085] rounded-md px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-indigo-500 sm:text-sm disabled:cursor-not-allowed disabled:opacity-70 disabled:bg-[#f2f2f2]"
          }
          onChange={onChange}
          value={value}
        />
        {type === "password" && (
          <>
            {inputType === "password" ? (
              <button
                className="absolute bottom-2 right-3"
                onClick={() => setInputType("text")}
                type="button"
              >
                <EyeSlashIcon className="h-5 w-5 text-primary" />
              </button>
            ) : (
              <button
                className="absolute bottom-2 right-3"
                onClick={() => setInputType("password")}
                type="button"
              >
                <EyeIcon className="h-5 w-5 text-primary" />
              </button>
            )}
          </>
        )}
        {setIcon === true && (
          <Icon className="absolute bottom-1/4 right-3 h-5 w-5 text-primary" />
        )}
      </div>
      {error && <ErrorMessage msg={error} />}
    </div>
  );
});

Input.displayName = "Input";
