import React from "react";
import { Button, Input } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

export const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
  } = useForm();
  return (
    <div className="pt-[40px]">
      <form className="space-y-12 ">
        <p className=" text-sm">
          Kindly enter your Internet Banking Email address to recover password
        </p>
        <div>
          <Input
            label="Email Address"
            placeholder="johndoe@gmail.com"
            id="email"
            borderNone={true}
            setIcon={true}
            autoComplete="false"
            {...register("password", { required: true })}
            error={errors.email && "Password is required"}
          />
          <div className="text-sm flex justify-end font-bold mt-2">
            <Link to={"/"} className=" text-primary">
              go back
            </Link>
          </div>
        </div>
      </form>
      <div className="pt-5 w-[80px] font-semibold">
        <Button
          onClick={() => {
            navigate("/verify-otp");
          }}
          isFullWidth
        >
          Next
        </Button>
      </div>
    </div>
  );
};
