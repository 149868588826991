import { EmptyState } from "../../../components";
import { UsersTable } from "./UsersTable";

export const RenderData = ({ data, initialSerialNumber, mutate }) => {
  return (
    <div>
      {data?.length === 0 || !data ? (
        <EmptyState title={"No User Found"} description="Please check back later" />
      ) : (
        <UsersTable
          data={data}
          initialSerialNumber={initialSerialNumber}
          mutate={mutate}
        />
      )}
    </div>
  );
};
