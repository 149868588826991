import { EmptyState } from "../../../components";
import { LoanTable } from "./LoanTable";

export const RenderData = ({ data, initialSerialNumber }) => {
  if (data?.length === 0 || !data) {
    return (
      <EmptyState
        title="No loan product found"
        description="Please check back later or clear search filter"
        showAction
      />
    );
  } else {
    return <LoanTable data={data} initialSerialNumber={initialSerialNumber} />;
  }
};
