import React from "react";
import { RenderData } from "./component/RenderData";
import ContentLoader from "react-content-loader";
import { useQuery } from "@tanstack/react-query";
import { mandateRuleService } from "../../../services/mandate.service";
import { useParams } from "react-router-dom";
import { SubHeading } from "../../../components";
export const MandateRule = () => {
  const {id}=useParams()
  const { data, isFetching } = useQuery({
    queryKey: ["get-mandates",id],
    queryFn: () => mandateRuleService.getAllMandateRule({account_id:id}),
    enabled:!!id
  });

  return (
    <div className="mt-8">
        <div className="flex flex-col lg:flex-row lg:items-center  justify-between lg:mb-3 mb-4">
          <SubHeading>List of mandate rule</SubHeading>
        </div>
        {isFetching ? (
          <ContentLoader viewBox="0 0 380 70">
            <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
          </ContentLoader>
        ) : (
          <div>
            <RenderData data={data} />
          </div>
        )}
    </div>
  );
};
