import React from "react";
import { data } from "./navData";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import {
  ArrowDown2,
  ArrowUp2,
  DocumentUpload,
  Money2,
  MoneyAdd,
  Trade,
} from "iconsax-react";
import { useState } from "react";
import { useEffect } from "react";

export const NavItem = ({ onClick }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [dropdownVisible2, setDropdownVisible2] = useState(false);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");
  const dropdownLinks = [
    {
      to: "/accounts",
      label: "Accounts",
      icon: Trade,
    },
    {
      to: "/accounts/batch-onboarding",
      label: "Batch Onboarding",
      icon: DocumentUpload,
    },
    {
      to: "/accounts/create-single",
      label: "Create account",
      icon: MoneyAdd,
    },
  ];
  const dropdownLinks2 = [
    {
      to: "/loan-products",
      label: "Loan Products",
      icon: Money2,
    },
    {
      to: "/loan-products/loan-applications",
      label: "Loan applications",
      icon: MoneyAdd,
    },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    if (!location.pathname.includes("accounts")) {
      setDropdownVisible(false);
      setActiveLink(location.pathname);
    }
  }, [location]);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const toggleDropdown2 = () => {
    setDropdownVisible2(!dropdownVisible2);
  };
  const isLinkActive = (targetUrl) => {
    return location.pathname === targetUrl;
  };
  return (
    <div className="">
      {data?.map((item) => (
        <div>
          {item?.title !== "accounts" && item?.to !== "/loan-products" ? (
            <NavLink
              onClick={() => {
                onClick();
                setDropdownVisible(false);
                setDropdownVisible2(false);
              }}
              className={({ isActive }) =>
                isActive && !dropdownVisible
                  ? `mb-5 capitalize flex items-center gap-4 text-[15px]  p-3 font-semibold  rounded-[6px] bg-[#e780202c] text-primary `
                  : `mb-5 capitalize flex items-center gap-4 text-[15px] p-3  text-primary font-normal`
              }
              to={item?.to}
            >
              {item?.iconLinear}
              <span> {item?.title}</span>
            </NavLink>
          ) : item?.to === "/loan-products" ? (
            <div className="">
              <button
                onClick={() => {
                  toggleDropdown2();
                  setDropdownVisible(false);
                  navigate("/loan-products");
                }}
                className={`${
                  dropdownVisible2 === true
                    ? "mb-7 capitalize flex items-center gap-4 text-[15px] rounded-[6px]  bg-[#e780202c] text-primary p-3  w-[200px] font-bold"
                    : "text-primary mb-7  capitalize flex items-center p-3 gap-4 text-[15px]"
                }`}
              >
                <span className={``}>{item.iconLinear}</span>
                <span> {item?.title}</span>
                {dropdownVisible2 ? (
                  <ArrowUp2 className="h-4 w-4 ml-3" />
                ) : (
                  <ArrowDown2 className="h-4 w-4 ml-3" />
                )}
              </button>
              {dropdownVisible2 && (
                <div className="dropdown rounded-[6px] bg-[#00800009] border">
                  <ul className="bg-[#ffffff0a] border border-[#ffffff69] rounded-md">
                    {dropdownLinks2.map((link) => (
                      <li
                        key={link.to}
                        className="my-6 text-[13px] text-gray-600 pl-3"
                      >
                        <NavLink
                          onClick={() => {
                            onClick();
                          }}
                          className={({ isActive }) => ""}
                          to={link.to}
                        >
                          <span className="flex items-center gap-3 text-primary">
                            <link.icon
                              variant={
                                isLinkActive(link.to) ? "Bold" : "Linear"
                              }
                              className="w-4"
                            />
                            <span
                              className={
                                isLinkActive(link.to)
                                  ? "border-b block w-fit border-primary"
                                  : ""
                              }
                            >
                              {link.label}
                            </span>
                          </span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div className="">
              <button
                onClick={() => {
                  toggleDropdown();
                  setDropdownVisible2(false);
                  navigate("/accounts");
                }}
                className={`${
                  dropdownVisible === true
                    ? "mb-7 capitalize flex items-center gap-4 text-[15px] rounded-[6px]  bg-[#e780202c] text-primary p-3  w-[200px] font-bold"
                    : "text-primary mb-7  capitalize flex items-center p-3 gap-4 text-[15px]"
                }`}
              >
                <span className={``}>{item.iconLinear}</span>
                <span> {item?.title}</span>
                {dropdownVisible ? (
                  <ArrowUp2 className="h-4 w-4 ml-3" />
                ) : (
                  <ArrowDown2 className="h-4 w-4 ml-3" />
                )}
              </button>
              {dropdownVisible && (
                <div className="dropdown rounded-[6px] bg-[#00800009] border">
                  <ul className="bg-[#ffffff0a] border border-[#ffffff69] rounded-md">
                    {dropdownLinks.map((link) => (
                      <li
                        key={link.to}
                        className="my-6 text-[13px] text-gray-600 pl-3"
                      >
                        <NavLink
                          onClick={() => {
                            onClick();
                          }}
                          className={({ isActive }) => ""}
                          to={link.to}
                        >
                          <span className="flex items-center gap-3 text-primary">
                            <link.icon
                              variant={
                                isLinkActive(link.to) ? "Bold" : "Linear"
                              }
                              className="w-4"
                            />
                            <span
                              className={
                                isLinkActive(link.to)
                                  ? "border-b block w-fit border-primary"
                                  : ""
                              }
                            >
                              {link.label}
                            </span>
                          </span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
