import http from "../../plugins/http";

class Organization {
  async getOrganization() {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_BASE_URL}/organizations/get-my-organization`
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}
export const organization = new Organization();
