import React from "react";
import { Badge,Container, Header, Heading } from "../../components";
import avatar from "../../assets/img/profile.png";
import { useProfile } from "./hooks/useAccount";
import { useStore } from "../../store/useStore";
export const AccountInfo = () => {
  const { data } = useProfile();
  const { user } = useStore();
  return (
    <div>
      <Header>
        <Heading>Profile Information</Heading>
      </Header>
      <div className="w-[600px]">
        <Container>
          <section className="p-3 text-[14px]">
            <div className="flex justify-between items-center">
              <Heading>My Profile</Heading>
              {/* <div className="text-sm flex items-center gap-2">
                <Link
                  to="/settings/profile"
                  className=" text-primary bg-[#e7802023] flex justify-center items-center h-[40px] w-[40px] rounded-full"
                >
                  <Edit variant="Bold" className="w-5 h-5 text-primary" />
                </Link>
              </div> */}
            </div>
            <dt className=" mt-3 flex  gap-2">
              <img
                src={avatar}
                alt="profiile"
                className="w-[100px] h-[100px] rounded-full object-cover"
              />
            </dt>
            <dl className="grid grid-cols-12  mt-6 border-b border-gray-300 pb-3">
              <dt className="col-span-6 text-gray-500">Organization Name:</dt>
              <dt className="col-span-4">
                {data?.organization?.organization_name ?? "..."}
              </dt>
            </dl>
            <dl className="grid grid-cols-12  mt-6 border-b border-gray-300 pb-3">
              <dt className="col-span-6 text-gray-500">Account Name:</dt>
              <dt className="col-span-4">{user ?? "..."}</dt>
            </dl>
            <dl className="grid grid-cols-12  mt-6 border-b border-gray-300 pb-3">
              <dt className="col-span-6 text-gray-500">Location:</dt>
              <dt className="col-span-4 text-start">
                {data?.organization?.location ?? "..."}
              </dt>
            </dl>

            <dl className="grid grid-cols-12  mt-6 border-b border-gray-300 pb-6">
              <dt className="col-span-6 text-gray-500 ">Email Address:</dt>
              <dt className="col-span-4 text-[14px]">
                {data?.organization?.email ?? "..."}
              </dt>
            </dl>
            <dl className="grid grid-cols-12  mt-6  pb-6">
              <dt className="col-span-6 text-gray-500">Onboarding Status:</dt>
              <dt className="col-span-4">
                <Badge status={data?.organization?.onboarding_status}>
                  {data?.organization?.onboarding_status ?? "..."}
                </Badge>
              </dt>
            </dl>
          </section>
        </Container>
      </div>
    </div>
  );
};
