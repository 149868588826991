import { Link } from "react-router-dom";
import {formatNaira } from "../../../../utils";
import { Badge } from "../../../../components/Badge/Badge";
import { Dropdown } from "flowbite-react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { useModal } from "../../../../hooks";
import { LoanApproval } from "./LoanApproval";
import { LoanDisbursement } from "./LoanDisbursement";
import { useState } from "react";

export const LoanTable = ({ data, refetch, initialSerialNumber }) => {
  const { showModal, Modal } = useModal();
  const { showModal: showModal2, Modal: Modal2 } = useModal();
  const [loan_application] = useState({});
  function formatDate(dateString) {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString();
  }
  
  return (
    <div>
      <div className="rounded-sm overflow-x-auto mb-4">
        <table className="min-w-full whitespace-nowrap ">
          <thead className="bg-[#FEF9F4]">
            <tr>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                S/N
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Product Name
              </th>

              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Status
              </th>

              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Amount
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Repayment Amount
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Interest
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Customer ID
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Time Created
              </th>

              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[12px]">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-800">
            {data?.map((item, i) => (
              <tr key={item.id}>
                <td className="px-3 py-6 text-[13px] whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {i + initialSerialNumber}.
                </td>

                <td className="px-3 py-6 text-[13px] whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {item?.loan_product?.name}
                </td>

                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {
                    <Badge status={item?.status}>
                      {item?.status.replace("_", "  ")}
                    </Badge>
                  }
                </td>
                {/* 
                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {item?.percentage}
                </td> */}
                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {formatNaira(item?.loan_amount)}
                </td>
                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {formatNaira(item?.repayment_amount)}
                </td>
                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {formatNaira(item?.interest)}
                </td>
                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {item?.customer_id}
                </td>
                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {formatDate(item?.created_at)}
                </td>
                {/* <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  <div key={i} className="flex items-center gap-2">
                    {item?.repayment_plan?.map((repay, i) => (
                      <div
                        className={`w-[56px] h-[26px] text-[12px] border rounded-xl flex items-center justify-center ${
                          repay?.paid === "paid"
                            ? "bg-green-700 text-white"
                            : repay?.paid === "not_paid"
                            ? "bg-red-600 text-white"
                            : "bg-gray-100"
                        }`}
                      >
                        <span>{repay?.due_date}</span>
                      </div>
                    ))}
                  </div>
                </td> */}

                <td className="px-3 py-6 whitespace-nowrap text-[13px]    text-primary border-b border-[#c0c0c052]">
                  <Dropdown
                    label={
                      <EllipsisHorizontalIcon className="h-7 w-7 text-primary" />
                    }
                    inline={true}
                    arrowIcon={false}
                  >
                    <Dropdown.Item>
                      <Link
                        className="text-primary block"
                        to={`/loan-products/loan-applications/${item?.id}`}
                      >
                        view loan
                      </Link>
                    </Dropdown.Item>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {Modal({
        children: (
          <LoanApproval
            loan_application={loan_application}
            showModal={showModal}
            refetch={refetch}
          />
        ),
      })}
      {Modal2({
        children: <LoanDisbursement showModal={showModal2} refetch={refetch} />,
      })}
    </div>
  );
};
