import { FilterComponent, Header, Heading, Pagination, SubHeading } from "../../../components";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
import { Link } from "react-router-dom";
import { RenderData } from "./components/RenderData";
import ContentLoader from "react-content-loader";
import { Add } from "iconsax-react";
import { useAccounts } from "./hooks/useAccounts";
import { limit } from "../../../constants/limit";
import { useState } from "react";

export const Account = () => {
  const [type, setType]=useState("")
  const [value, setValue]=useState(undefined)
  const {
    page,
    setPage,
    params,
    data,
    isLoading,
    isFetching,
    initialSerialNumber,
    refetch
  } = useAccounts(limit, type, value);
const filterOptions=["All", "Retail", "Corporate"]
  return (
    <div>
      <Header>
        <div className="flex items-center gap-3">
          <div>
            <Heading>Accounts</Heading>
            <p className="mt-1">List of all accounts created</p>
          </div>
          <SearchFilter placeholder="Search by account name or account number" value={value} setValue={setValue} onSearch={()=>{
          refetch()
        }}/>
        </div>
      </Header>
      <div className="flex flex-col lg:flex-row lg:items-center item-start justify-between lg:mb-3 mb-5">
      <SubHeading>All Accounts Created</SubHeading>
        <div className="flex gap-6"> 
        <FilterComponent onSelect={(value)=>{
          setType(value==="All"?"":value)
       
        }} options={filterOptions} placeholder="Filter account type"/>
          <Link
            to="/accounts/create-single"
            className="flex items-center gap-2 text-[14px] font-semibold text-primary border-[1px] border-[#e780208a] px-3 py-2 rounded-md w-fit"
          >
            <Add className="w-5 h-5" />
            Create Account
          </Link>
        </div>
      </div>
      <div>
        {isLoading || isFetching ? (
          <ContentLoader viewBox="0 0 380 70">
            <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
          </ContentLoader>
        ) : (
          <div>
            <RenderData data={data} initialSerialNumber={initialSerialNumber} />
            {data?.length !== 0 && (
              <div className="flex items-center justify-center mt-4">
                <Pagination
                  itemsPerPage={params?.limit}
                  totalItems={data?.meta?.total}
                  currentPage={page}
                  handlePageClick={setPage}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
