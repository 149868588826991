import { useNavigate } from "react-router-dom";
import { useStore } from "../store/useStore";

export const useLogout = () => {
  const navigate = useNavigate();

  const handleLogout = (state = {}) => {
    useStore.setState({ user: null });
    localStorage.removeItem("token");
    localStorage.removeItem("user_Id");
   
    navigate("/", { replace: true, state });
  };

  return { handleLogout };
};
