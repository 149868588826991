import { EmptyState } from "../../../../components";
import { LoanRepayment } from "./LoanTable";

export const RenderData = ({ data }) => {
  if (data?.length === 0 || !data) {
    return (
      <EmptyState
        title="No loan repayment schedule"
        description="Please come back later"
        showAction
      />
    );
  } else {
    return <LoanRepayment data={data} />;
  }
};
