import { BrifecaseTick, MoneyAdd} from "iconsax-react";
import { useAccounts } from "../../Onboarding/Account/hooks/useAccounts";

export const useCard = () => {
  const {data:retail}=useAccounts(50 ,"Retail")
  const {data:corporate}=useAccounts(50 ,"Corporate")
  const {data:totalAccounts}=useAccounts(50)
  const cardData = [
    {
      id: 1,
      title: "Number of corporate accounts",
      amount:`${corporate?.meta?.total??"0"} corporate accounts`,
      icon: BrifecaseTick,
    },
    {
      id: 1,
      title: "Number of retail accounts",
      amount:`${retail?.meta?.total??"0"} retail accounts`,
      icon: BrifecaseTick,
    },
    {
      id: 3,
      title: "Total accounts",
      amount:`${totalAccounts?.meta?.total??"0"}  accounts`,
      icon: MoneyAdd,
    },
  ];
  return {
    cardData,
  };
};
