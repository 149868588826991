import React from "react";
import { Header, Heading, SubHeading } from "../../../components";
import { Bubble } from "iconsax-react";
import { useParams } from "react-router-dom";
import { RenderDetails } from "../components/RenderDetails";
import { Spinner } from "../../../components/Spinner";
import { HandleGoBack } from "../../../components/handleGoBack/HandleGoBack";
export const TicketDetails = ({ details, isLoading, ticketResponse }) => {
  const { id } = useParams();

  return (
    <div>
      <Header>
        <Heading>Ticket Details</Heading>
        <p className="mt-2">
          {isLoading
            ? "......"
            : details?.user && (
                <p className="flex items-center gap-2">
                  <Bubble className="w-5 h-5 text-primary" />
                  {details?.user}
                </p>
              )}
        </p>
      </Header>
      {isLoading ? (
        <p className="text-[15px] font-semibold  flex flex-col h-[50vh] items-center justify-center">
          <Spinner></Spinner>
          <SubHeading>Fetching ticket details</SubHeading>
        </p>
      ) : (
        <div>
          {Object.keys(details)?.length !== 0 && (
            <div className="col-span-12 flex justify-end items-center">
              <HandleGoBack />
            </div>
          )}
          <RenderDetails ticketResponse={ticketResponse} details={details} />
        </div>
      )}
    </div>
  );
};
