import http from "../../plugins/http";
import { notifyError, notifySuccess } from "../../utils/toast";

class User {
  async getUserProfile() {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_BASE_URL}/users/profile`
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async createRep(payload) {
    try {
      const response = await http.post(`${process.env.REACT_APP_CONSOLE_URL}/back-office/admins`, payload);
      notifySuccess(response?.message);
      return response.data;
    } catch (error) {
      notifyError(error?.response?.data?.message);
      throw new Error(error);
    }
  }
  async getRep() {
    try {
      const response = await http.get(`${process.env.REACT_APP_CONSOLE_URL}/back-office/admins`);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async resendEmailVerification(payload) {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/users/resend-verification-email`,
        {...payload}
      );
      notifySuccess(response?.message);
      return response.data;
    } catch (error) {
      notifyError(error?.response?.data?.message);
      throw new Error(error);
    }
  }
  async getUserCount(params) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/users`,
        { params }
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getUsers(params) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/users`,
        { params }
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getUser(id) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/users/${id}`,
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getUserAccount(params) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/users`,
        {params}
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export const userService = new User();





