import { useForm } from "react-hook-form";
import { useHandleNextStep } from "../../../utils/useHandleNextStep";
import { StepOne } from "./components/Stepone";
import { StepTwo } from "./components/Steptwo";
import { Container, Header, Heading } from "../../../components";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
import { HandleGoBack } from "../../../components/handleGoBack/HandleGoBack";
import { useNavigate } from "react-router-dom";

export const AddLoanProduct = ({ showModal }) => {
  const {
    control,
    watch,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const { handleNextStep, handlePreviousStep, currentStep } =
    useHandleNextStep(2);
  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <StepOne
            handleSubmit={handleSubmit}
            control={control}
            register={register}
            errors={errors}
            currentStep={currentStep}
            setValue={setValue}
            watch={watch}
            handleNextStep={handleNextStep}
          />
        );
      case 2:
        return (
          <StepTwo
            handleSubmit={handleSubmit}
            control={control}
            watch={watch}
            register={register}
            setValue={setValue}
            errors={errors}
            handlePrev={handlePreviousStep}
            currentStep={currentStep}
            showModal={showModal}
            navigate={navigate}
          />
        );

      default:
        return null;
    }
  };
  return (
    <div>
      <Header>
        <div className="flex flex-col lg:flex-row lg:items-center items-start ml-2 lg:ml-0">
          <Heading> Create loan product</Heading>
        </div>
      </Header>
      <HandleGoBack />
      <div className="w-[500px]">
        <Container>{renderFormStep()}</Container>
      </div>
    </div>
  );
};
