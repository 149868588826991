import { Dropdown } from "flowbite-react";
import { Avatar } from "../../../../components";
import { Eye } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../../hooks";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { formatDate } from "../../../../utils";
import { AccountDetails } from "./AccountDetail";
export const AccountTable = ({ data, initialSerialNumber }) => {
  const { Modal: OtpModal, showModal: showOTP } = useModal();
  const navigate = useNavigate();
  return (
    <div className="rounded-sm overflow-hidden overflow-x-auto mb-4">
      <table className="min-w-full ">
        <thead>
          <tr>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              S/N
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Account Name
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Account Type
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Account Number
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Time Created
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[12px]">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-800">
          {data?.items?.map((item, i) => (
            <tr key={i}>
              <td className="px-3 py-4 text-[13px] whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {initialSerialNumber + i}
              </td>

              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]capitalize">
                <div className="flex items-center gap-2">
                  {<Avatar name={item?.account_name} />}
                  {item?.account_name}
                </div>
              </td>
              <td className="px-3 py-4 text-[13px]   whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                <div className="flex items-center gap-3">{item?.type}</div>
              </td>
              <td className="px-3 py-4 text-[13px]   whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                <div className="flex items-center gap-3">
                  {item?.account_number}
                </div>
              </td>
              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {formatDate(item?.createdAt)}
              </td>
              <td className="px-3 py-4 whitespace-nowrap text-[13px] border-b border-[#c0c0c052]">
                <div>
                  <Dropdown
                    label={
                      <EllipsisHorizontalIcon className="h-7 w-7  text-primary font-semibold" />
                    }
                    inline={true}
                    arrowIcon={false}
                    className="p-1"
                  >
                    <Dropdown.Item>
                      <div
                        onClick={() => {
                          navigate(`/accounts/${item?._id}/overview`);
                        }}
                        className="flex items-center gap-1  cursor-pointer"
                      >
                        <Eye className="w-4 h-4" />
                        <span>view account</span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
