import React from "react";
import { Button, Card, Header, SubHeading } from "../../components";
import { useCard } from "./component/MockData";
import { HeaderDetails } from "./component/HeaderDetails";
import ContentLoader from "react-content-loader";

import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store/useStore";
import { RenderData } from "../Onboarding/Account/components/RenderData";
import { useAccounts } from "../Onboarding/Account/hooks/useAccounts";

export const Dashboard = () => {
  const { cardData, isLoading: amountLoading } = useCard();
  const { user } = useStore();
  const navigate = useNavigate();
  const { data, initialSerialNumber, isLoading } = useAccounts(10);

  return (
    <div>
      <Header>
        <HeaderDetails user={user} />
      </Header>
      <div className="mb-4">
        <SubHeading>Account Overview</SubHeading>
      </div>
      <div className="grid grid-cols-12 gap-7">
        {cardData.map((item) => (
          <div className="lg:col-span-4 col-span-12">
            <Card card={item} key={item?.id} isLoading={amountLoading} />
          </div>
        ))}
        <div className="col-span-12 mt-10">
          <div className="flex justify-between items-center mb-6 ">
            <SubHeading>List of accounts created</SubHeading>
            <Button
              onClick={() => {
                navigate("/accounts");
              }}
            >
              <div className="flex items-center gap-2">
                <span> view all</span>
                <ArrowRightIcon className="w-5" />
              </div>
            </Button>
          </div>
          {isLoading ? (
            <ContentLoader viewBox="0 0 380 70">
              <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
            </ContentLoader>
          ) : (
            <RenderData
              data={data}
              initialSerialNumber={initialSerialNumber}
              view
            />
          )}
        </div>
      </div>
    </div>
  );
};
