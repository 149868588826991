import { Money2, MoneyForbidden, MoneyRecive, Receive } from "iconsax-react";
export const cardData = [
  {
    id: 1,
    title: "Amount Disbursed",
    amount: "₦ 24,056,786.76",
    icon: Money2,
  },
  {
    id: 2,
    title: "Disburse Pending",
    amount: "₦ 14,056,786.76",
    icon: Money2,
  },
  {
    id: 3,
    title: "Declined Request",
    amount: 249,
    icon: MoneyForbidden,
  },
  {
    id: 4,
    title: "Approved Request",
    amount: 1239,
    icon: Receive,
  },
];
