import { useQuery } from "@tanstack/react-query";
import { accountService } from "../../../../services/accounts/account.service";
import { useTableSerialNumber } from "../../../../hooks";
import { limit as amount } from "../../../../constants/limit";
import { useState } from "react";

export const useAccounts = (limit = amount, type = "", search) => {
  const [page, setPage] = useState(1);
  const params = {
    page: page,
    limit: limit,
    type: type,
    search:search
  };
  const { data, isLoading, isFetching , refetch} = useQuery({
    queryKey: ["get-accounts", params],
    queryFn: () => accountService.getAllAccounts(params),
  });

  const initialSerialNumber = useTableSerialNumber(page);
  return {
    page,
    setPage,
    params,
    data,
    isLoading,
    isFetching,
    refetch,
    initialSerialNumber,
  };
};
