import React from "react";
import {
  Container,
  Header,
  Heading,
  Pagination,
  SubHeading,
} from "../../components";
import SearchFilter from "../../components/SearchFilter/SearchFilter";
import { Link } from "react-router-dom";
import { Add } from "iconsax-react";
import ContentLoader from "react-content-loader";
import { RenderData } from "./components/RenderData";
import { usePagination, useTableSerialNumber } from "../../hooks";
import { TabFilter } from "../Dashboard/component/TabFilter";
export const Ticket = ({ data, isLoading }) => {
  const { paginatedData, page, setPage } = usePagination(data);
  const initialSerialNumber = useTableSerialNumber(page);
  const tab = [
    {
      time: "All",
    },
    {
      time: "Open",
    },
    {
      time: "In Progress",
    },
    {
      time: "Resolved",
    },
    {
      time: "Closed",
    },
  ];
  return (
    <div>
      <Header>
        <div className="flex items-center gap-3">
          <Heading>Ticket Requests</Heading>
          <SearchFilter placeholder={"Search by event, time or user....."} />
        </div>
      </Header>
      <div className=" lg:mb-3 mb-7">
        <SubHeading>All Tickets</SubHeading>
      </div>
      <div className="my-4 flex flex-col lg:flex-row lg:items-center item-start justify-between">
        <TabFilter data={tab} />
        <div>
          <Link
            to="/ticket/create"
            className="flex items-center gap-2 text-[14px] font-semibold text-primary border-[1px] border-[#e780208a] px-3 py-2 rounded-md w-[140px]"
          >
            <Add className="w-5 h-5" />
            Create ticket
          </Link>
        </div>
      </div>
      {isLoading ? (
        <ContentLoader viewBox="0 0 380 70">
          <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
        </ContentLoader>
      ) : (
        <div>
          <RenderData
            data={paginatedData}
            initialSerialNumber={initialSerialNumber}
          />
          {data?.length !== 0 && (
            <div className="flex items-center justify-center mt-4">
              <Pagination
                totalItems={data?.length}
                currentPage={page}
                handlePageClick={setPage}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
