import {
  ExportButton,
  Header,
  Heading,
  SubHeading,
  Card,
} from "../../../components";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
import { RenderData } from "./components/RenderData";
import { data as mockData } from "./components/MockData";
import { formatNaira } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { loanService } from "../../../services/loan/loan.service";
import ContentLoader from "react-content-loader";
import { useParams } from "react-router-dom";
import { useProfile } from "../../Account/hooks/useAccount";
import { Calendar } from "iconsax-react";
import { LoanProductId } from "./components/LoanProductId";
export const LoanProductType = ({ amountLoading = false }) => {
  const { id } = useParams();
  const {
    data: loanApplication,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["loan-applications"],
    queryFn: () => loanService.getLoanApplications(),
  });
  const { data: profile } = useProfile();
  const params = {
    organization_id: profile?.organization?.organization_id,
  };
  const { data: loanProduct, isFetching } = useQuery({
    queryKey: ["loan-product", id, params],
    queryFn: () => loanService.getLoanProduct(id, params),
    enabled: !!id && !!params.organization_id,
  });

  const cardData = [
    {
      id: 1,
      title: "Max Loan Amount",
      amount: formatNaira(loanProduct?.max_loan_amount ?? "00"),
    },
    {
      id: 2,
      title: "Min Loan Amount",
      amount: formatNaira(loanProduct?.min_loan_amount ?? "00"),
    },
    {
      id: 3,
      title: "Min Tenor",
      amount: `${loanProduct?.min_tenor} ${loanProduct?.tenor_type}`,
      icon: Calendar,
    },
    {
      id: 4,
      title: "Max Tenor",
      amount: `${loanProduct?.max_tenor} ${loanProduct?.tenor_type}`,
      icon: Calendar,
    },
  ];
  return (
    <div>
      {" "}
      <Header>
        <div className="flex flex-col lg:flex-row lg:items-center items-start ml-2 lg:ml-0">
          <div>
            <Heading>{loanProduct?.name ?? "...."}</Heading>
            <h1 className="mt-2">
              Product Code: {loanProduct?.product_code ?? "...."}
            </h1>
          </div>
          <SearchFilter
            placeholder={"Search by loan type......"}
            noBorder={true}
          />
        </div>
      </Header>
      {isFetching ? (
        <ContentLoader viewBox="0 0 380 70">
          <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
        </ContentLoader>
      ) : (
        <LoanProductId data={loanProduct} />
      )}
    </div>
  );
};
