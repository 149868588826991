import { formatDate, formatNaira } from "../../../../utils";
import { Badge } from "../../../../components/Badge/Badge";

export const LoanRepayment = ({ data }) => {
  return (
    <div>
      <div className="rounded-sm overflow-x-auto mb-4">
        <table className="min-w-full whitespace-nowrap ">
          <thead className="bg-[#FEF9F4]">
            <tr>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                S/N
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Loan Amount
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Loan Interest
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Repayment Amount
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Total Repayment Amount
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
               Status
              </th>
              <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
                Repayment Date
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-800">
            {data?.map((item, i) => (
              <tr key={i}>
                <td className="px-3 py-6 text-[13px] whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {i + 1}.
                </td>

                <td className="px-3 py-6 text-[13px] whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {formatNaira(item?.loan_application?.loan_amount)}
                </td>

                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {formatNaira(item?.loan_application?.interest)}
                </td>

                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {formatNaira(item?.amount)}
                </td>
                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {formatNaira(item?.loan_application?.repayment_amount)}
                </td>
                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                <Badge status={item?.status}>
                  {item?.status}
                </Badge>
                </td>

                <td className="px-3 py-6 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                  {formatDate(item?.repayment_date, false)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
