import { useForm } from "react-hook-form";
import {
  Button,
  Container,
  Header,
  Heading,
  Input,
  Label,
  Select,
} from "../../../../components";
import { useModal } from "../../../../hooks";
import DynamicForm from "./DynamicForm";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { loanService } from "../../../../services/loan/loan.service";

export const Request = ({ handleNext }) => {
  const { control } = useForm();
  const { id } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ["loan-product", id],
    queryFn: () => loanService.getLoanProduct(id),
    enabled: !!id,
  });

  return (
    <div>
      <Header>
        <Heading>Loan Application</Heading>
      </Header>
      <div className="w-[550px]">
        <Container>
          <Heading size="23" pos="start">
            Loan application for {data?.name}
          </Heading>
          <p className="text-[14px] text-gray-  mt-2">
            Note that the interest rate is calculated based on the loan type
            been requested, amount and repayment duration you choose
          </p>

          <DynamicForm isFetching={isFetching} data={data} />
        </Container>
      </div>
    </div>
  );
};
