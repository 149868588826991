import { useNavigate } from "react-router-dom";
import { EmptyState } from "../../../components";
import { ArrowLeft } from "iconsax-react";
import { TicketResponse } from "../TicketDetails/TicketResponse";
import { TicketDetailsList } from "../TicketDetails/TicketDetailList";
export const RenderDetails = ({ details, ticketResponse }) => {
  const navigate = useNavigate();
  if (Object.keys(details)?.length === 0) {
    return (
      <EmptyState
        title="No ticket details found"
        description="Please check your internet connection or reload."
        showAction
        Icon={ArrowLeft}
        action={{
          label: "go back",
          onClick: () => navigate("/ticket"),
        }}
      />
    );
  } else {
    return (
      <div className="grid grid-cols-12 gap-10 mt-12 lg:mt-5">
        <div className="lg:col-span-6 col-span-12">
          <TicketDetailsList details={details} />
        </div>
        <div className="lg:col-span-6 col-span-12 bg-white shadow-md rounded-lg border  border-orange-200 ">
          <TicketResponse ticketResponse={ticketResponse} />
        </div>
      </div>
    );
  }
};
