import {
  ArrowCircleDown2,
  Calendar1,
  Calendar2,
  Clock,
  Receipt1,
  Receipt2,
} from "iconsax-react";
import { Avatar, Badge, ReceiptButton } from "../../../components";
import { formatDate, formatNaira, truncateText } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../hooks";
import { TransactionDetails } from "./TransactionDetails";
import { useState } from "react";

export const TransactionTable = ({ data, view, initialSerialNumber }) => {
  const { Modal, showModal } = useModal();
  const [receiptDetails, setReceiptDetails] = useState();
  const navigate = useNavigate();
  return (
    <div className=" overflow-hidden overflow-x-auto mb-4 ">
      <table className="min-w-full">
        <thead>
          <tr>
            <th className="px-3 py-3 text-left   font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              S/N
            </th>

            {/* <th className="pl-5 py-3 text-left  font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Beneficiary
            </th> */}

            <th className="px-3 py-3 text-left  font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Amount
            </th>
            <th className="px-3 py-3 text-left   font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Reference
            </th>
            <th className="px-3 py-3 text-left   font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              ID
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Transaction date
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Status
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Receipt
            </th>
          </tr>
        </thead>

        <tbody className="">
          {data?.map((item, i) => (
            <tr key={i}>
              <td className="px-3 py-4 text-[13px] whitespace-nowrap text-[#6E6B7B]">
                {initialSerialNumber ? initialSerialNumber + i : i + 1}
              </td>

              {/* <td className="px-2 py-4 text-[13px]  whitespace-nowrap text-[#3D4355]">
                {item?.account_number}
              </td> */}
              <td className="px-2 py-4 text-[13px]  whitespace-nowrap text-[#3D4355] ">
                {item?.amount}
              </td>
              <td className="px-2 py-4 text-[13px]  whitespace-nowrap text-[#3D4355]">
                {item?.instrument_no}
              </td>
              <td className="px-2 py-4 text-[13px]  whitespace-nowrap text-[#3D4355]">
                {item?.reference_id}
              </td>
              <td className="px-2 py-4 text-[13px]  whitespace-nowrap text-[#3D4355]">
                {formatDate(item?.current_date)}
              </td>
              <td className="px-2 py-4 text-[13px]  whitespace-nowrap text-[#3D4355] font-semibold">
                <Badge status={item?.record_type}>{item?.record_type}</Badge>
              </td>

              <td className="px-3 py-4 whitespace-nowrap text-[13px]    text-primary border-b border-[#c0c0c052]">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    showModal();
                    setReceiptDetails(item);
                  }}
                >
                  <ArrowCircleDown2 className="w-5 h-5" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        {Modal({
          children: (
            <div>
              <TransactionDetails
                showModal={showModal}
                receiptDetails={receiptDetails}
              />
            </div>
          ),
          showCloseIcon: false,
          bg: "white",
          size: "md",
        })}
      </table>
    </div>
  );
};
