import React, { useState } from "react";
import banner from "../../assets/img/hand.jpg";
import Logo from "../Logo/Logo";
import { CloseCircle } from "iconsax-react";
export const AuthWrapper = ({ children, title, showInstruction }) => {
  const [hide, setHide] = useState(false);
  return (
    <div>
      <div>
        {showInstruction && (
          <div>
            {" "}
            {hide === false && (
              <div
                className={`${
                  hide === true && "opacity-0 duration-100"
                } absolute right-7 z-[100000] top-24 w-[260px] h-auto bg-white rounded-2xl p-5`}
              >
                <div className="text-sm">
                  <CloseCircle
                    onClick={() => {
                      setHide(true);
                    }}
                    variant="Bold"
                    className="w-6 h-6 text-primary block absolute right-4 top-3 cursor-pointer"
                  />
                  <p className=" mt-8">
                    You can login with the demo details provided below
                  </p>
                  <p className="mt-2 text-primary font-bold">
                    Email: yarkajorto@gufum.com
                  </p>
                  <p className="text-primary mt-1 font-bold">
                    Password: Demo@1234
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className="flex  gap-12 justify-between  h-[100vh] items-center px-20 relative z-[10]"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute top-0 left-0 h-full w-full bg-[#e780203d] -z-10"></div>
        {/* <div className="  lg:w-[60%]  w-[100%]  shadow-inner col-span-6 h-[100vh] relative overflow-hidden">
          <div className="svg h-[200px] w-[200px] absolute bottom-0"></div>
          <div className="svg h-[200px] w-[200px] absolute top-0 right-0 rotate-[180deg]"></div>
          <div className="w-full h-full bg-[#8080801e] absolute z-30 flex items-center justify-center ">
            <div
              className="h-[400px] w-[400px] rounded-full relative z-6"
              style={{
                backgroundImage: `url(${banner})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
        </div> */}

        <div className="bg-white p-10  lg:w-[470px]  w-[100%]  shadow-xl col-span-6 flex justify-center  flex-col  rounded-md">
          <div className="">
            <div className="w-[190px]   rounded-[10px] h-[50px] bg-white mb-1 ">
              <Logo />
            </div>
            <h1 className="font-bold text-[27px] ">{title && title}</h1>
            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
