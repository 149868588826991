import { Button, Container, FileUpload } from "../../../../components";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BatchTable } from "./BatchTable";
import { accountService } from "../../../../services/accounts/account.service";
import { useMutation } from "@tanstack/react-query";
import { useProfile } from "../../../Account/hooks/useAccount";
export const BatchUpload = ({ convertFile, file, clearFile, jsonArray }) => {
  const { data: organization } = useProfile();
  const [duplicateAccounts, setDuplicateAccounts] = useState([]);
  const [duplicateUsers, setDuplicateUsers] = useState([]);
  const [invalidAccounts, setInvalidAccounts] = useState([]);
  const {
    handleSubmit,
  } = useForm();
  const navigate = useNavigate();
  const resetErrors = () => {
    setDuplicateAccounts([]);
    setDuplicateUsers([]);
    setInvalidAccounts([]);
  };
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => accountService.onboardMutipleAccount(data),
    onSuccess: () => {
      navigate("/accounts");
    },
  });
  const handleUpload = (data) => {
    mutate({
      organization_id: organization?.organization?.organization_id,
      file,
    });
  };
  return (
    <div>
      <form onSubmit={handleSubmit(handleUpload)} className="mt-7">
        <FileUpload
          accept=".csv, .xlsx, .xls"
          id="file-upload"
          infoText="Upload a CSV or Excel file"
          file={file}
          btn
          onChange={(file) => {
            convertFile(file);
          }}
          removeFile={() => {
            clearFile();
            resetErrors();
          }}
        >
          {file ? (
            <div>
              <div className="flex justify-between items-center my-6">
                <div className="w-[300px]">
                </div>
                <Button type="submit" disabled={isPending}>
                  Upload file
                </Button>
              </div>
              <Container>
                {jsonArray.length > 0 && <BatchTable items={jsonArray} />}
              </Container>
            </div>
          ) : null}
        </FileUpload>
      </form>
    </div>
  );
};
