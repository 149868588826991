import http from "../../plugins/http";
import { notifyError, notifySuccess } from "../../utils/toast";

class AccountService {
  async onboardSingleAccountCorporate(payload) {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_CONSOLE_URL}/accounts/create-corporate-account`,
        payload
      );
      notifySuccess(response?.message);
      return response.data;
    } catch (error) {
      notifyError(error?.response?.data?.message);
      return Promise.reject(error);
    }
  }
  async onboardSingleAccountRetail(payload) {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_CONSOLE_URL}/accounts/create-retail-account`,
        payload
      );
      notifySuccess(response?.message);
      return response.data;
    } catch (error) {
      notifyError(error?.response?.data?.message);
      return Promise.reject(error);
    }
  }
  async onboardMutipleAccount({ organization_id, file }) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("organization_id", organization_id);
    try {
      const data = await http.post(
        `${process.env.REACT_APP_CONSOLE_URL}/accounts/onboard-multiple-accounts`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      notifySuccess(data?.message ?? "");
      return data;
    } catch (error) {
      notifyError(error?.response?.data?.message, "error");
      throw new Error(error);
    }
  }

  async getAccountRoles() {
    try {
      const response = await http.get("/accounts/settings/roles");
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getAllAccounts(params) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/organizations/accounts`,
        { params }
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getAccountByID(params) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/accounts/details`,
        { params }
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getTotalAccounts() {
    try {
      const response = await http.head(
        `${process.env.REACT_APP_CONSOLE_URL}/organizations/accounts`,
        
      );
      return response.headers['total-count'];
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getRoleByID(roleId) {
    try {
      const response = await http.get(`/accounts/settings/roles/${roleId}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async acccountLookup(params) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/transaction/account_lookup_no_bankCode`,
        { params }
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const accountService = new AccountService();
 