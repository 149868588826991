import { useMutation } from "@tanstack/react-query";
import { Button, SubHeading, TextArea } from "../../../../components";
import { loanService } from "../../../../services/loan/loan.service";
import { useForm } from "react-hook-form";

export const LoanApproval = ({
  refetch,
  showModal,
  loan_application,
  refetch2,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => loanService.LoanApproval(data),
    onSuccess: () => {
      showModal();
      refetch();
      refetch2();
    },
  });
  const onSubmit = (data) => {
    mutate({
      loan_application_id: loan_application?.id,
      reason: data?.reason,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
      <SubHeading>Loan Approval</SubHeading>
      <TextArea
        id="reason"
        label="Reason"
        placeholder="Enter reason...."
        {...register("reason", { required: true })}
        error={errors?.reason && "Reason is required"}
      />
      <Button disabled={isPending} type="submit" isFullWidth>
        Approve
      </Button>
    </form>
  );
};
export const LoanDisburseApproval = ({
  refetch,
  showModal,
  loan_application,
  refetch2,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => loanService.LoanDisburseApproval(data),
    onSuccess: () => {
      showModal();
      refetch();
      refetch2();
    },
  });
  const onSubmit = (data) => {
    mutate({
      loan_application_id: loan_application?.id,
      reason: data?.reason,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
      <SubHeading>Loan Disburse Approval</SubHeading>
      <TextArea
        id="reason"
        label="Reason"
        placeholder="Enter reason...."
        {...register("reason", { required: true })}
        error={errors?.reason && "Reason is required"}
      />
      <Button disabled={isPending} type="submit" isFullWidth>
        Approve
      </Button>
    </form>
  );
};
export const LoanRejection = ({
  refetch,
  showModal,
  loan_application,
  refetch2,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => loanService.LoanRejection(data),
    onSuccess: () => {
      showModal();
      refetch();
      refetch2();
    },
  });
  const onSubmit = (data) => {
    mutate({
      loan_application_id: loan_application?.id,
      reason: data?.reason,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
      <SubHeading>Loan Rejection</SubHeading>
      <TextArea
        id="reason"
        label="Reason"
        placeholder="Enter reason...."
        {...register("reason", { required: true })}
        error={errors?.reason && "Reason is required"}
      />
      <Button disabled={isPending} type="submit" isFullWidth>
        Reject
      </Button>
    </form>
  );
};
export const LoanDisburseRejection = ({
  refetch,
  showModal,
  loan_application,
  refetch2,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => loanService.LoanDisburseRejection(data),
    onSuccess: () => {
      showModal();
      refetch();
      refetch2();
    },
  });
  const onSubmit = (data) => {
    mutate({
      loan_application_id: loan_application?.id,
      reason: data?.reason,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
      <SubHeading>Loan Disburse Rejection</SubHeading>
      <TextArea
        id="reason"
        label="Reason"
        placeholder="Enter reason...."
        {...register("reason", { required: true })}
        error={errors?.reason && "Reason is required"}
      />
      <Button disabled={isPending} type="submit" isFullWidth>
        Reject
      </Button>
    </form>
  );
};
