import http from "../../plugins/http";
import { notifyError, notifySuccess } from "../../utils/toast";

class LoanService {
  async LoanApplication(payload) {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/loan-applications`,
        payload
      );
      notifySuccess(response?.message);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.errors && Object.keys(error?.response?.data?.errors)?.length !== 0) {
        notifyError(
          error?.response?.data?.errors?.map((item) => item?.msg)
        );
      } else {
        notifyError(
          error?.response?.data?.message ?? error?.response?.data?.message
        );
      }
      return Promise.reject(error);
    }
  }
  async createLoanProduct(payload) {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/loan-products`,
        payload
      );
      notifySuccess(response?.message);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.errors && Object.keys(error?.response?.data?.errors)?.length !== 0) {
        notifyError(
          error?.response?.data?.errors?.map((item) => item?.msg)
        );
      } else {
        notifyError(
          error?.response?.data?.message ?? error?.response?.data?.message
        );
      }
      return Promise.reject(error);
    }
  }
  async getLoanProducts(params) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/loan-products`,
        { params }
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getLoanProduct(productId, params) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/loan-products/${productId}`,
        {
          params,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async toggleStatus(productId, params) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/loan-products/${productId}/toggle-status`,
        {
          params,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getLoanApplications(params) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/loan-applications`,
        { params }
      );

      return response.data;

    } catch (error) {
  
      throw new Error(error);
    }
  }
  async getLoanApplication(applicationId) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/loan-applications/${applicationId}`
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getLoanApplicationDecision(params) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/loan-application-decisions`,
        { params }
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async LoanDisburseApproval(payload) {
    try {
      const response = await http.put(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/loan-disbursements/approve`,
        payload
      );
      notifySuccess("loan disbursal approved successfully");
      return response.data;
    } catch (error) {
      notifyError(error?.response?.data?.message);
      throw new Error(error);
    }
  }

  async LoanDisburseRejection(payload) {
    try {
      const response = await http.put(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/loan-disbursements/reject`,
        payload
      );
      notifySuccess("loan disbursal rejected successfully");
      return response.data;
    } catch (error) {
      if (error?.response?.data?.errors && Object.keys(error?.response?.data?.errors)?.length !== 0) {
        notifyError(
          error?.response?.data?.errors?.map((item) => item?.msg)
        );
      } else {
        notifyError(
          error?.response?.data?.message ?? error?.response?.data?.message
        );
      }
      throw new Error(error);
    }
  }

  async LoanApproval(payload) {
    try {
      const response = await http.put(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/loan-application-decisions/approve`,
        payload
      );
      notifySuccess(response?.message ?? "Loan approved successfully");
      return response.data;
    } catch (error) {
      notifyError(error?.response?.data?.message);
      throw new Error(error);
    }
  }
  async LoanRejection(payload) {
    try {
      const response = await http.put(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/loan-application-decisions/reject`,
        payload
      );
      notifySuccess(response?.message ?? "loan application rejected");
      return response.data;
    } catch (error) {
      if (error?.response?.data?.errors && Object.keys(error?.response?.data?.errors)?.length !== 0) {
        notifyError(
          error?.response?.data?.errors?.map((item) => item?.msg)
        );
      } else {
        notifyError(
          error?.response?.data?.message ?? error?.response?.data?.message
        );
      }
      throw new Error(error);
    }
  }
  async getLoanRepayment(id) {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/back-office/loan-repayments/schedule?loan_application_id=${id}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export const loanService = new LoanService();
