import { useForm } from "react-hook-form";
import { Button, Input } from "../../../components";
import { useMutation } from "@tanstack/react-query";
import { authService } from "../../../services/auth/auth.service";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Shield } from "iconsax-react";
import { useStore } from "../../../store/useStore";

export const TwoFA = ({ payloadData = {}, handlePrev }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [question, setQuestion] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [authToken, setAuthToken] = useState("");
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => authService.login(data),
    onSuccess: (data) => {
      localStorage.setItem(
        "user",
        `${data?.user?.first_name} ${data?.user?.last_name}`
      );
      useStore.setState({
        user: localStorage.getItem("user"),
      });
      localStorage.setItem("user_Id", data?.user?._id);
      navigate("/dashboard");
    },
    onError: (res) => {
      setQuestion(res?.data?.message?.data?.question?.question ?? "");
      setQuestionId(res?.data?.message?.data?.question?._id ?? "");
      setAuthToken(res?.data?.message?.data["2fa_token"] ?? "");
    },
  });

  const onSubmit = (data) => {
    const payload = {
      token: authToken || payloadData["2fa_token"],
      questionId: questionId || payloadData?.question?._id,
      answer: data?.answer,
    };
    mutate(payload);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <p className="text-primary font-[500] mb-3 mt-2 py-2">
        <span className="text-gray-800 block my-2">Question:</span>
        {question || payloadData?.question?.question}
      </p>
      <div className="space-y-9">
        <Input
          label="Answer"
          id="answer"
          borderNone={true}
          {...register(`answer`, { required: true })}
          error={errors.answer ? "This field is required" : ""}
        />
        <Button isFullWidth type="submit" disabled={isPending}>
          Submit
        </Button>
        <span
          onClick={handlePrev}
          className="text-primary font-[500] block text-center cursor-pointer"
        >
          Go back to login
        </span>
      </div>
    </form>
  );
};
