export const data = [
  {
    title: "API Console",
    to: "/api-console",
  },
  {
    title: "BVN Usage",
    to: "/api-console/bvn",
  },
];
export const ApiData = [
  {
    name: "LAPO Organisation",
    total: "20 enquiries",
    time: "07 May2022 08:32 AM",
  },
  {
    name: "LAPO Organisation",
    total: "40 enquiries",
    time: "27 June 2022 02:32 PM",
  },
  {
    name: "LAPO Organisation",
    total: "10 enquiries",
    time: "28 July 2023 12:32 AM",
  },
  {
    name: "LAPO Organisation",
    total: "70 enquiries",
    time: "27 June 2022 08:32 AM",
  },
  {
    name: "LAPO Organisation",
    total: "80 enquiries",
    time: "19 August 2022 04:32 AM",
  },
  {
    name: "LAPO Organisation",
    total: "80 enquiries",
    time: "29 August 2023 01:32 AM",
  },
];
