import { Dropdown } from "flowbite-react";
import { Avatar, Badge } from "../../../components";
import { Call } from "iconsax-react";
import { useModal } from "../../../hooks";
import { useState } from "react";
import { Profile } from "./Profile";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { useRoles } from "../../Settings/Roles/hook/useRoles";

export const UsersTable = ({ data, initialSerialNumber, mutate}) => {

  const { Modal: profile, showModal: showProfile} = useModal();
  const [user, setUser] = useState({});
  const {role}=useRoles(user?.role_id)

  return (
    <div className="rounded-sm overflow-hidden overflow-x-auto mb-4">
      <table className="min-w-full ">
        <thead>
          <tr>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              S/N
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Name & Email
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[12px]">
              Phone Number
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[12px]">
              Gender
            </th>

            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[12px]">
              Status
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider  text-[12px]">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-800">
          {data?.map((item, i) => (
            <tr key={item?._id}>
              <td className="px-3 py-4 text-[13px] whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {initialSerialNumber ? initialSerialNumber + i : i + 1}
              </td>

              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                <div className="flex items-center gap-3">
                  <Avatar name={`${item?.first_name} ${item?.last_name}`} />
                  <div>
                    <p>{item?.email}</p>
                    <p className="mt-1">
                      {" "}
                      {`${item?.first_name} ${item?.last_name}`}
                    </p>
                  </div>
                </div>
              </td>

              <td className="px-3 py-4 whitespace-nowrap text-[13px]     border-b border-[#c0c0c052]">
                <div className="flex items-center gap-3">
                  <Call variant="Bold" className="w-5 text-primary" />
                  <p className="text-[#6E6B7B] text-[13px]">
                    {" "}
                    {item?.phone_number}
                  </p>
                </div>
              </td>
              <td className="px-3 py-4 text-[13px] capitalize  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {item?.gender}
              </td>
              <td className="px-3 py-4 whitespace-nowrap text-[13px]  border-b border-[#c0c0c052]">
                <Badge status={item?.status}>{item?.status}</Badge>
              </td>
              <td className="px-3 py-4 whitespace-nowrap text-[13px]   border-b border-[#c0c0c052]">
                <Dropdown
                  label={
                    <EllipsisHorizontalIcon className="h-7 w-7 text-primary" />
                  }
                  inline={true}
                  arrowIcon={false}
                >
                  <Dropdown.Item
                    onClick={() => {
                      setUser(item);
                      showProfile();
                    }}
                  >
                    View profile
                  </Dropdown.Item>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
        {/* {Modal({
          children: (
            <div>
              <Otp user={user} cancel={showModal} />
            </div>
          ),
          showCloseIcon: true,
          size: "sm",
        })} */}
        {profile({
          children: (
            <div>
              <Profile role={role?.name} user={user} />
            </div>
          ),
          showCloseIcon: true,
          size: "sm",
        })}
        {/* {email({
          children: (
            <div>
              <UpdateEmail user={user} cancel={showEmail} />
            </div>
          ),
          showCloseIcon: true,
          size: "sm",
        })} */}
      </table>
    </div>
  );
};
