import { Routes, Route, Outlet } from "react-router-dom";
import { Wrapper } from "../../components";
import { Dashboard } from "../../pages/Dashboard/Dashboard";
import TransactionHistory from "../../pages/TransactionHistory/TransactionHistory";
import { Transfer } from "../../pages/Transfers/Transfer";
import TransferDetails from "../../pages/Transfers/TransferDetails/TransferDetails";
import { Ticket } from "../../pages/Tickets/Ticket";
import { TicketDetails } from "../../pages/Tickets/TicketDetails/TicketDetails";
import { Audit } from "../../pages/Audit/Audit";
import { authRoute } from "./AuthRoute";
import { MandateRule } from "../../pages/Settings/MandateRule/MandateRule";
import { Settings } from "../../pages/Settings/Settings";
import { Security } from "../../pages/Settings/Security/Security";
import { Report } from "../../pages/Reports/Report";

import { CreateTicket } from "../../pages/Tickets/CreateTicket/CreateTicket";
import { CreateMandate } from "../../pages/Settings/MandateRule/MandateForm/CreateMandate";
import { Profile } from "../../pages/Settings/Profile/Profile";
import { AccountInfo } from "../../pages/Account/Account";
import { SingleAccount } from "../../pages/Onboarding/Account/SingleAccount/SingleAccount";
import { BatchOnboard } from "../../pages/Onboarding/Account/BatchAccount/BatchOnboard";
import { AccountOverview } from "../../pages/Onboarding/Account/AccountOverview";
import { Overview } from "../../pages/Onboarding/Account/OverViewAccount/OverView";
import { Account } from "../../pages/Onboarding/Account/Account";
import { UserManagement } from "../../pages/UserManagement/UserManagement";
import { Api } from "../../pages/ApiConsole/Api/Api";
import { Bvn } from "../../pages/ApiConsole/Bvn/Bvn";
import { PrivateOutlet } from "../PrivateOutLet";
import { Themes } from "../../pages/Settings/Themes/Themes";
import { Roles } from "../../pages/Settings/Roles/Role";
import { RoleId } from "../../pages/Settings/Roles/RoleId";
import { LoanProduct } from "../../pages/Loan/LoanProduct/LoanProduct";
import { LoanProductType } from "../../pages/Loan/LoanProductType/LoanProductType";
import { LoanRepaymentSchedule } from "../../pages/Loan/LoanUser/LoanUser";
import { AddLoanProduct } from "../../pages/Loan/LoanProduct/AddLoanProduct";
import { LoanApplications } from "../../pages/Loan/LoanApplication/LoanApplications";
import { LoanApplicationId } from "../../pages/Loan/LoanApplication/LoanProductId";
import { Request } from "../../pages/Loan/LoanProductType/components/Request";
import { UserAccount } from "../../pages/Onboarding/Account/OverViewAccount/components/User";
export const Router = () => {
  return (
    <div>
      <Routes>
        {authRoute.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route element={<PrivateOutlet />}>
          <Route
            element={
              <Wrapper>
                <Outlet />
              </Wrapper>
            }
          >
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              element={
                <Settings>
                  <Outlet />
                </Settings>
              }
            >
              <Route path={"/settings"}>
                <Route index element={<Security/>} />
                <Route path="profile" element={<Profile />} />
                <Route path="themes" element={<Themes />} />
                <Route path="roles">
                  <Route index element={<Roles />} />

                  <Route path=":id" element={<RoleId />} />
                </Route>
                <Route path="security">
                  <Route index element={<Security />} />
                </Route>
                <Route path="mandate-rule">
                  <Route index element={<MandateRule />} />
                  <Route path=":id" element={<CreateMandate />}>
                    <Route path="create" element={<CreateMandate />} />
                    <Route path="edit" element={<CreateMandate />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="/loan-products">
              <Route index element={<LoanProduct />} />
              <Route path="add-product" element={<AddLoanProduct />} />
              <Route path="loan-applications" element={<LoanApplications />} />
              <Route
                path=":id/loan-applications/create"
                element={<Request />}
              />
              <Route
                path="loan-applications/:id"
                element={<LoanApplicationId />}
              />
              <Route path="product/:id" element={<LoanProductType />} />
              <Route
                path="loan-applications/repayment-schedule/:id"
                element={<LoanRepaymentSchedule />}
              />
            </Route>
            <Route path={"/api-console"}>
              <Route index element={<Api />} />
              <Route path="bvn" element={<Bvn />} />
            </Route>
            <Route
              path="/transaction-history"
              element={<TransactionHistory />}
            />
            <Route path="/admin-management" element={<UserManagement />} />
            <Route path="/report" element={<Report />} />
            <Route path="/audit-logs" element={<Audit />} />
            <Route path="/profile" element={<AccountInfo />} />
            <Route path="/transfer-request">
              <Route index element={<Transfer />} />
              <Route
                path="/transfer-request/details/:id"
                element={<TransferDetails />}
              />
            </Route>
            <Route path="accounts">
              <Route index element={<Account />} />
              <Route path="batch-onboarding" element={<BatchOnboard />} />
              <Route path="create-single" element={<SingleAccount />} />
              <Route path=":id" element={<AccountOverview />}>
                <Route path="overview" element={<Overview />} />
                <Route
                  path="users"
                  element={<UserAccount />}
                />

                <Route
                  path="transfer-requests"
                  element={<Transfer noHeader={true} />}
                />
                <Route
                  path="transfer-requests/:id"
                  element={<TransferDetails />}
                />
                <Route
                  path="mandate-rule"
                  element={<MandateRule noHeader={true} />}
                />
                <Route
                  path="audit-trails"
                  element={<Audit noHeader={true} />}
                />
                <Route
                  path="transaction-history"
                  element={<TransactionHistory noHeader={true} />}
                />
              </Route>
            </Route>
            <Route path="/ticket">
              <Route index element={<Ticket />} />
              <Route path="/ticket/details/:id" element={<TicketDetails />} />
              <Route path="/ticket/create" element={<CreateTicket />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </div>
  );
};
