import React, { useState } from "react";
import { Button, Heading, Input } from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useModal } from "../../../../hooks";
import { Verify } from "iconsax-react";

export const VerifyOtpForm = () => {
  const navigate = useNavigate();
  const { Modal, showModal } = useModal();
  const [success, setSuccess] = useState(false);
  const {
    register,
    formState: { errors },
  } = useForm();
  return (
    <div className="pt-[40px]">
      <form className="space-y-12 ">
        <p className=" text-sm">
          Kindly enter your six digit OTP sent sent to your account email
          address
        </p>
        <div>
          <Input
            label="Enter OTP"
            placeholder="xxxxxx"
            id="OTP"
            borderNone={true}
            autoComplete="false"
            {...register("password", { required: true })}
            error={errors.email && "Password is required"}
          />
          <div className="text-sm flex justify-end font-bold mt-2">
            <Link to={"/"} className=" text-primary">
              go back
            </Link>
          </div>
        </div>
      </form>
      <div className="pt-5 w-[80px] font-semibold">
        <Button
          onClick={() => {
            showModal();
          }}
          isFullWidth
        >
          Verify
        </Button>
      </div>
      {Modal({
        children: (
          <div className="text-center flex flex-col gap-12">
            <h1 className="text-[25px]">Account Verification Successful</h1>
            <Verify variant="Bold" className="text-primary w-12 h-12 m-auto" />

            <Button
              onClick={() => {
                navigate("/new-password");
              }}
              isFullWidth
            >
              Continue
            </Button>
          </div>
        ),
        showCloseIcon: false,
        size: "sm",
      })}
    </div>
  );
};
