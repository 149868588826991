import classnames from "classnames";
import CircleLoader from "react-spinners/ClipLoader";

export const Button = (props) => {
  const {
    type = "button",
    children,
    onClick,
    variant = "primary",
    disabled,
    isFullWidth = false,
  } = props;
  return (
    <button
      className={classnames(
        `flex justify-center rounded-md border  py-2 px-4 text-sm items-center shadow-2xl
          font-medium 
          disabled:cursor-not-allowed`,
        {
          "bg-primary text-white disabled:opacity-50": variant === "primary",
          "bg-[#e7d1e1] text-primary border-indigo border-2":
            variant === "secondary",
          "bg-red-600 text-white hover:bg-red-500": variant === "danger",
          "bg-green-600 text-white hover:bg-green-500": variant === "success",
          "bg-transparent text-gray-500 hover:bg-gray-50 border-[1px] border-[#e7802071] rounded-[5px]":
            variant === "outline",
          "bg-gray-700 text-white": variant === "black",
          "bg-transparent !border-0 shadow-none": variant === "transparent",
          "w-full": isFullWidth,
        }
      )}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {disabled ? <CircleLoader size={25} color={"#fff"} /> : children}
    </button>
  );
};
