import React from "react";
import { Pagination, SubHeading } from "../../components";
import { useState } from "react";
import ContentLoader from "react-content-loader";
import { useTableSerialNumber } from "../../hooks";
import { RenderData } from "./components/RenderData";
import { limit } from "../../constants/limit";
import { useQuery } from "@tanstack/react-query";
import { transactionService } from "../../services/transaction.service";
import { useParams } from "react-router-dom";
import SearchFilter from "../../components/SearchFilter/SearchFilter";

export const Transfer = ({ children }) => {
  const [page, setPage] = useState(1);
  const [value, setValue] = useState(undefined);
  const { id } = useParams();
  const params = {
    limit: limit,
    page: page,
    account_id: id,
    search: value,
  };
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["all-transfers", params, id],
    queryFn: () => transactionService.getAllTransfers(params),
    enabled: !!id,
  });

  const initialSerialNumber = useTableSerialNumber(page);

  return (
    <div className="mt-8">
      <div className="flex flex-col lg:flex-row lg:items-center justify-between mb-3 mt-16 lg:mt-0">
        <div>
          <p className="my-3">All transfers made in your account. </p>
        </div>
      </div>
      <div className="mb-6">
        <SearchFilter
          position="none"
          placeholder={"Search by amount, reference ID, account number"}
          value={value}
          setValue={setValue}
          onSearch={refetch}
        />
      </div>
      {isFetching ? (
        <ContentLoader viewBox="0 0 380 70">
          <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
        </ContentLoader>
      ) : (
        <div>
          <RenderData
            data={data?.items}
            initialSerialNumber={initialSerialNumber}
          />
          {data?.length !== 0 && (
            <div className="flex flex-col lg:flex-row items-center  justify-center mb-3 gap-y-6">
              <Pagination
                itemsPerPage={params.limit}
                totalItems={data?.meta?.total}
                currentPage={page}
                handlePageClick={setPage}
              />
            </div>
          )}
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};
