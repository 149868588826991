import React from "react";
import { Link, useLocation } from "react-router-dom";
import { data } from "./MockData";
import { Header, Heading } from "../../../components";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
export const ApiWrapper = ({ children }) => {
  const location = useLocation();

  return (
    <div>
      <Header>
        <div className="flex items-center gap-3">
          <div>
            <Heading>Api Console</Heading>
            <p className="mt-1">All api usage/bvn request made</p>
          </div>
          <SearchFilter placeholder={"Search by event, time or user....."} />
        </div>
      </Header>

      <div className="flex items-center gap-[60px]">
        {data.map((item) => (
          <Link
            to={item.to}
            className={`${
              location.pathname === item.to
                ? " bg-primary  text-white  rounded-md"
                : ""
            } text-[14px]  px-4   py-2 tracking-wide`}
          >
            {item.title}
          </Link>
        ))}
      </div>

      {children}
    </div>
  );
};
