import { EmptyState } from "../../../components";
import { TransferTable } from "./TransferTable";

export const RenderData = ({ data, initialSerialNumber }) => {
  if (data?.length === 0 || !data) {
    return (
      <EmptyState
        title="No transfer found"
        description="You have not made any transfer yet."

      />
    );
  } else {
    return (
      <TransferTable data={data} initialSerialNumber={initialSerialNumber} />
    );
  }
};
