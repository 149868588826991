import http from "../plugins/http";


class MandateRuleService {

  async getAllMandateRule(params) {
    try {
      const { data } = await http.get(`${process.env.REACT_APP_CONSOLE_URL}/back-office/accounts/mandate-rules`,{params});
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const mandateRuleService = new MandateRuleService();
