import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FileUpload,
  Input,
  Label,
  Select,
  TextArea,
} from "../../../../components";
import { Spinner } from "../../../../components/Spinner";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { loanService } from "../../../../services/loan/loan.service";
import { OptionButton } from "../../../../components/OptionButton/OptionButton";
import { useNavigate } from "react-router-dom";

import { formatNaira } from "../../../../utils";
import { meta_Data } from "../../../../services/metaData/meta.service";
import { useAccounts } from "../../../Onboarding/Account/hooks/useAccounts";
import { userService } from "../../../../services/users/user.service";

function DynamicForm({ data, isFetching }) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  const [files, setFiles] = useState([]);
  const { data: accounts } = useAccounts(50, "Retail");
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const customer_id = watch("customer_id")?.value;
  const { mutate, isPending } = useMutation({
    mutationFn: (data) => loanService.LoanApplication(data),
    onSuccess: () => {
      navigate("/loan-products/loan-applications");
    },
  });
  const { data: user, isFetching: isFetchingUser } = useQuery({
    queryKey: ["user", customer_id],
    queryFn: () => userService.getUsers({account_id:customer_id}),
    enabled: !!customer_id,
  });
  const onSubmit = async (formData) => {
    const uploadPromises = [];
    setSubmitting(true);
    for (let i = 0; i < data.requirements.length; i++) {
      if (data.requirements[i].inputType === "file") {
        const file = getValues(`fileType${i}`);
        if (file) {
          uploadPromises.push(meta_Data.mediaUpload(file));
        }
      }
    }
    let urls;
    try {
      urls = await Promise.all(uploadPromises);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      console.error("Error uploading media:", error);
      return;
    }
    const payload = data.requirements.map((requirement, index) => {
      let value;
      if (requirement.inputType === "checkbox") {
        value = formData[`checkbox${index}`];
      } else if (requirement.inputType === "select") {
        value = formData[`select${index}`]?.value;
      } else if (requirement.inputType === "number") {
        value = formData[`input_number${index}`];
      } else if (requirement.inputType === "file") {
        value = urls?.shift();
      } else if (requirement.inputType === "radio") {
        value = formData[`optionButton${index}`];
      } else {
        value = formData[`input${index}`];
      }
      return { label: requirement.label, value };
    });
    const user_Id = user?.items.map((item) => item?.user_id);
    const finalPayload = {
      loan_amount: Number(formData.amount),
      loan_product_id: data?.id,
      tenor: Number(formData.tenor),
      customer_id:user_Id[0],
      customer_account_id: formData?.customer_id?.value,
      created_by: "123456",
      payload,
    };

    mutate(finalPayload);
  };

  return (
    <>
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <p className="border-b py-2 text-primary font-doppin">
          *Customer's information
        </p>
        <Select
          label="Customer account"
          name={`customer_id`}
          control={control}
          options={accounts?.items?.map((account) => ({
            label: `${account?.account_name} (${account?.account_number})`,
            value: account?.account_id,
          }))}
          error={errors.customer_id && `Customer account is required`}
        />
        <div>
          {isFetchingUser ? (
            <p className="text-secondary">Fetching customer name.....</p>
          ) : (
            <>
              {customer_id && (
                <Input
                  placeholder="Customer name"
                  id="customer_name"
                  label="Customer name"
                  value={user?.items.map(
                    (item) => `${item?.first_name} ${item?.last_name}`
                  )}
                  disabled
                />
              )}
            </>
          )}
        </div>
        <div>
          <Input
            placeholder="Enter amount"
            id="amount"
            label="Amount"
            {...register("amount", { required: true })}
            error={errors.amount && "Amount is required"}
          />
          <span className="text-sm block mt-2  text-right">{`Min amount(${formatNaira(
            data?.min_loan_amount ?? "0.0"
          )}) - Max amount(${formatNaira(
            data?.max_loan_amount ?? "0.0"
          )}) `}</span>
        </div>
        <div>
          <Input
            type="text"
            id="tenor"
            label="Tenor"
            {...register("tenor", { required: true })}
            error={errors?.tenor && "Tenor is required"}
          />
          <span className="text-sm block mt-2  text-right">{`Loan product tenor is between ${
            data?.min_tenor ?? "0"
          } and ${data?.max_tenor ?? "0"} ${data?.tenor_type ?? "..."}`}</span>
        </div>

        <p className="border-b py-2 text-primary font-doppin">
          *Loan requirements
        </p>
        {isFetching ? (
          <Spinner />
        ) : (
          <div className="space-y-4">
            {" "}
            {data?.requirements.map((requirement, index) => (
              <div key={index} className="space-y-4">
                {requirement.inputType === "text" && (
                  <Input
                    label={`${requirement.label} ${
                      requirement?.required === false ? "(optional)" : ""
                    }`}
                    type="text"
                    id={`input${index}`}
                    required={requirement.required}
                    {...register(`input${index}`, {
                      required: requirement?.required,
                    })}
                    error={
                      errors[`input${index}`] &&
                      `${requirement.label} is required`
                    }
                  />
                )}
                {requirement.inputType === "number" && (
                  <Input
                    label={`${requirement.label} ${
                      requirement?.required === false ? "(optional)" : ""
                    }`}
                    type="number"
                    id={`input_number${index}`}
                    required={requirement.required}
                    {...register(`input_number${index}`, {
                      required: requirement?.required,
                    })}
                    error={
                      errors[`input_number${index}`] &&
                      `${requirement.label} is required`
                    }
                  />
                )}
                {requirement.inputType === "email" && (
                  <Input
                    id={`input${index}`}
                    label={`${requirement.label} ${
                      requirement?.required === false ? "(optional)" : ""
                    }`}
                    type="email"
                    required={requirement.required}
                    {...register(`input${index}`, {
                      required: requirement?.required,
                    })}
                    error={
                      errors[`input${index}`] &&
                      `${requirement.label} is required`
                    }
                  />
                )}
                {requirement.inputType === "textarea" && (
                  <TextArea
                    id={`input${index}`}
                    label={`${requirement.label} ${
                      requirement?.required === false ? "(optional)" : ""
                    }`}
                    {...register(`input${index}`, {
                      required: requirement?.required,
                    })}
                    error={
                      errors[`input${index}`] &&
                      `${requirement.label} is required`
                    }
                  />
                )}
                {requirement.inputType === "select" && (
                  <Select
                    label={`${requirement.label} ${
                      requirement?.required === false ? "(optional)" : ""
                    }`}
                    name={`select${index}`}
                    required={requirement.required}
                    control={control}
                    options={requirement.options?.map((option) => ({
                      label: option?.label,
                      value: option?.value.toLowerCase(),
                    }))}
                    error={
                      errors[`select${index}`] &&
                      `${requirement.label} is required`
                    }
                  />
                )}
                {requirement.inputType === "radio" && (
                  <div className="space-y-4">
                    <Label
                      label={`${requirement.label} ${
                        requirement?.required === false ? "(optional)" : ""
                      }`}
                    ></Label>
                    {requirement.options?.map((option, i) => (
                      <OptionButton
                        key={i}
                        name={`optionButton${index}`}
                        title={option.label}
                        checked={false}
                        value={option.value}
                        onChange={(e) => {
                          setValue(
                            `optionButton${index}`,
                            e.target.value.toLowerCase()
                          );
                        }}
                        error={
                          errors[`optionButton${index}`] &&
                          `${requirement.label} is required`
                        }
                      />
                    ))}
                  </div>
                )}
                {requirement.inputType === "file" && (
                  <FileUpload
                    name="Upload file"
                    id={`fileType${index}`}
                    accept={requirement?.fileType
                      ?.map((fileType) => fileType.value)
                      .join(",")}
                    file={files[index]}
                    label={`Upload your ${requirement.label} file ${
                      requirement.required === false ? "(optional)" : ""
                    }`}
                    onChange={(e) => {
                      const newFiles = [...files];
                      if (e.target.files && e.target.files.length > 0) {
                        newFiles[index] = e.target.files[0];
                        setFiles(newFiles);

                        setValue(`fileType${index}`, newFiles[index]);
                       
                      }
                    }}
                  />
                )}

                {requirement.inputType === "checkbox" && (
                  <Checkbox
                    title={`${requirement.label} ${
                      requirement?.required === false ? "(optional)" : ""
                    }`}
                    id={`checkbox${index}`}
                    checked={false}
                    onChange={(e) => {
                      setValue(`checkbox${index}`, e.target.checked);
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        )}

        <div className="w-[500px]">
          <Button
            disabled={isSubmitting || isPending}
            type="submit"
            isFullWidth
          >
            Create loan application
          </Button>
        </div>
      </form>
    </>
  );
}

export default DynamicForm;
