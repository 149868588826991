import http from "../../plugins/http";

class MetaData {
  async getMetaData() {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CONSOLE_URL}/meta-data/providers?type=corebanking&status=active`
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async mediaUpload(file) {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await http.post(
        `${process.env.REACT_APP_CONSOLE_URL}/media/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data?.url;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export const meta_Data = new MetaData();
