import { EmptyState } from "../../../../components";
import { AccountTable } from "./AccountTable";
import { useNavigate } from "react-router-dom";
export const RenderData = ({ data, initialSerialNumber, search }) => {
  const navigate = useNavigate();
  if (data?.items?.length === 0 || !data?.items) {
    return (
      <EmptyState
        title="No account found"
        description={"Please check back later or create new account"}
        showAction
        action={{
          label: "Create an account",
          onClick: () => navigate("/accounts/create-single"),
        }}
      />
    );
  } else {
    return (
      <AccountTable data={data} initialSerialNumber={initialSerialNumber} />
    );
  }
};
