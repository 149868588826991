import {
  DocumentIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { truncateText } from "../../utils";
import { Label } from "../Form/Label/Label";
import { useRef, useEffect, useState } from "react";
import { CloudAdd, Document, Export, Folder2 } from "iconsax-react";
import { Button } from "../Button/Button";
import { CloudArrowUpIcon } from "@heroicons/react/20/solid";

export const FileUpload = (props) => {
  const dragAndDropRef = useRef();
  const [isDragActive, setIsDragActive] = useState(false);
  const {
    Icon = Folder2,
    infoText,
    label,
    accept,
    id,
    onChange,
    file,
    removeFile,
    children,
    btn,
    width = "w-[500px]",
    text = " Drag and drop your file here or browse file",
  } = props;

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let files = [...e.dataTransfer.files];
    if (files && files.length > 0) {
      onChange({
        ...e,
        target: { files },
      });
    }
    e.dataTransfer.clearData();
    setIsDragActive(false);
  };

  useEffect(() => {
    const dropContainer = dragAndDropRef.current;
    if (dropContainer) {
      dropContainer.addEventListener("dragenter", handleDragEnter);
      dropContainer.addEventListener("dragleave", handleDragLeave);
      dropContainer.addEventListener("dragover", handleDragOver);
      dropContainer.addEventListener("drop", handleDrop);
    }
    return () => {
      if (dropContainer) {
        dropContainer.removeEventListener("dragenter", handleDragEnter);
        dropContainer.removeEventListener("dragleave", handleDragLeave);
        dropContainer.removeEventListener("dragover", handleDragOver);
        dropContainer.removeEventListener("drop", handleDrop);
      }
    };
  }, [dragAndDropRef]);

  const opacity = isDragActive ? "0.5" : "1";

  return (
    <div>
      {label && <Label label={label} />}
      <div className="" ref={dragAndDropRef} style={{ opacity }}>
        <div className={` ${width}  `}>
          <div className="space-y-1  border shadow-md p-4 bg-white  rounded-md">
            <p className="pb-3 text-[#3D4355] text-[15px]">{infoText}</p>
            <div className=" text-sm text-gray-600   w-full">
              <label
                htmlFor={id}
                className="relative cursor-pointer rounded-md bg-white font-lighter text-primary border-2 border-dashed border-gray-200 py-4 focus-within:outline-none  block bg-[#e7802011]"
              >
                {!file ? (
                  <span className="flex items-center gap-6 text-[14px] justify-center flex-col">
                    <Icon variant="Bold" className=" h-16 w-16 text-primary" />
                    {!file && (
                      <div className="flex items-center justify-center flex-col gap-4">
                        <p className="text-center text-gray-500 text-[15px]">
                          {text}
                        </p>
                      </div>
                    )}
                  </span>
                ) : (
                  <div className=" w-full shadow-md border flex items-center justify-between px-4">
                    <Document className="w-12 h-12 " />
                    <div>
                      <span className="flex items-center  font-semibold w-full h-[70px] bg-gray-50  px-2 text-sm  text-gray-700  text-center">
                        {truncateText(file.name, 28)}
                      </span>
                      <p className="h-[6px] bg-[#e78020b9] mb-3 rounded-2xl"></p>
                    </div>
                    <XMarkIcon className="h-6 w-6" onClick={removeFile} />
                  </div>
                )}
                <input
                  id={id}
                  name={id}
                  type="file"
                  className="sr-only block"
                  accept={accept}
                  onChange={onChange}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      {file && <div className="pt-2">{children}</div>}
    </div>
  );
};
