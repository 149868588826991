import { EmptyState } from "../../../../components";
import { MandateTable } from "./MandateTable";
export const RenderData = ({ data }) => {
  if (data?.length === 0 || !data) {
    return (
      <EmptyState
        title="No mandate rule created"
        description="You have not created any mandate yet."
        showAction
      />
    );
  } else {
    return <MandateTable data={data} />;
  }
};
