import React from "react";
import { Button, Input } from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

export const CreateNewpasswordForm = () => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
  } = useForm();
  return (
    <div className="pt-[40px]">
      <form className="space-y-12 ">
        <p className=" text-sm">
          Please secure your password, create a strong password to avoid scam or
          illegal activities
        </p>
        <div>
          <Input
            label="New Password"
            type="password"
            placeholder="xxxxx"
            borderNone={true}
            id="psw"
            autoComplete="false"
            {...register("password", { required: true })}
            error={errors.email && "Password is required"}
          />
        </div>

        <Input
          label="Confirm Password"
          borderNone={true}
          placeholder="xxxxx"
          type="password"
          id="confirm_psw"
          autoComplete="false"
          {...register("password", { required: true })}
          error={errors.email && "Password is required"}
        />
        <div className="text-sm flex justify-end font-bold mt-2">
          <Link to={"/"} className=" text-primary">
            go back
          </Link>
        </div>
      </form>
      <div className="pt-5 w-[80px] font-semibold">
        <Button
          onClick={() => {
            navigate("/");
          }}
          isFullWidth
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
