
import { Checkbox, Input, Select, TextArea } from "../../../../components";
import { AddCircle } from "iconsax-react";
import { useRoles } from "../../../Settings/Roles/hook/useRoles";
import { useAccounts } from "../../../Onboarding/Account/hooks/useAccounts";

export const StepOne = ({
  handleNextStep,
  currentStep,
  control,
  register,
  handleSubmit,
  errors,
  setValue,
  watch,
}) => {
  const onSubmit = () => {
    handleNextStep();
  };
  const { data } = useRoles();
  const { data: accounts } = useAccounts(50,"Retail");

  return (
    <div>
      <div className=" flex justify-between items-center">
        <h1 className="font-semibold ">Add a New Product</h1>
        <span>Step {currentStep}/2</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 mt-5">
        <Input
          id="name"
          {...register("name", { required: true })}
          error={errors.name && "Product name is required"}
          label="Product name"
          placeholder="Product name"
        />

        <Input
          label="Min loan amount"
          id="min_loan_amount"
          type="number"
          {...register("min_loan_amount", { required: true })}
          error={errors.min_loan_amount && "Min loan amount is required"}
        />
        <Input
          label="Max loan amount"
          id="max_loan_amount"
          type="number"
          {...register("max_loan_amount", { required: true })}
          error={errors.max_loan_amount && "Max loan amount is required"}
        />
        <Input
          label="Interest rate %"
          id="interest"
          type="number"
          placeholder="Set percentage insterest"
          {...register("interest", { required: true })}
          error={errors.interest && "Interest is required"}
        />
        <TextArea
          id="details"
          {...register("details", { required: true })}
          error={errors.details && "Product description is required"}
          label="Description"
          placeholder="Product description"
        />
        <p className="border-b-[2px] pb-2 w-fit border-primary">* Loan tenor</p>
        <Select
          label="Tenor type"
          name="tenor_type"
          control={control}
          placeholder="Select tenor type"
          options={[
            { label: "Days", value: "days" },
            { label: "Months", value: "months" },
            { label: "Years", value: "years" },
            { label: "Weeks", value: "weeks" },
          ]}
          error={errors.tenor_type && "Tenor type is required"}
        />

        <Checkbox
          id="is_multi"
          checked={false}
          title="Allow multi tenor (optional)"
          onChange={(e) => {
            setValue("is_multi", e.target.checked);
          }}
        />

        <div className="space-y-6">
          <Input
            label="Min loan tenor"
            id="min_loan_tenor"
            type="number"
            {...register("min_loan_tenor", { required: true })}
            error={errors.min_loan_tenor && "Min loan tenor is required"}
          />
          <Input
            label="Max loan tenor"
            id="max_loan_tenor"
            type="number"
            {...register("max_loan_tenor", { required: true })}
            error={errors.max_loan_tenor && "Max loan tenor is required"}
          />
        </div>

        <p className=" pb-2 w-fit border-primary border-b-[2px]"> * Approval</p>
        <Input
          type="number"
          label=" Minimum number of authorization"
          id="minimun_number_of_authorization_approval"
          {...register("minimun_number_of_authorization_approval", {
            required: true,
          })}
          error={
            errors.minimun_number_of_authorization_approval &&
            "Authorizer is required"
          }
          placeholder="Enter authorisers"
        />
        <Select
          label="Role"
          name="role_authoriser"
          control={control}
          placeholder="Select role"
          options={data?.map((role) => ({
            label: role?.name,
            value: role?._id,
          }))}
        />
        <p className=" pb-2 w-fit border-primary border-b-[2px]">
          {" "}
          * Disbursement
        </p>
        <Select
          name={"disbursement_account_id"}
          label="Disbursement account"
          control={control}
          options={accounts?.items?.map((account) => ({
            label: `${account?.account_name} (${account?.account_number})`,
            value: account?.account_id,
          }))}
          error={
            errors.disbursement_account_id && "Disbursement account is required"
          }
          placeholder="Choose account"
        />
        <Select
          label="Role"
          name="role"
          control={control}
          placeholder="Select role"
          options={data?.map((role) => ({
            label: role?.name,
            value: role?._id,
          }))}
        />
        <p className=" pb-2 w-fit border-primary border-b-[2px]">
          {" "}
          * Repayment
        </p>
        <Select
          name={"repayment_account_id"}
          label="Repayment account"
          control={control}
          options={accounts?.items?.map((account) => ({
            label: `${account?.account_name} (${account?.account_number})`,
            value: account?.account_id,
          }))}
          error={
            errors.repayment_account_id && "Repayment account is required"
          }
          placeholder="Choose account"
        />
        <button
          type="submit"
          className="flex items-center gap-3 font-[500] cursor-pointer"
        >
          <AddCircle variant="Bold" className="w-5 h-5 text-primary" />
          <span className="text-primary">add requirements section</span>
        </button>
      </form>
    </div>
  );
};
