import React from "react";
import { SingleAccountForm } from "../components/SingleAccountForm";
import {
  Card,
  Container,
  Header,
  Heading,
  SubHeading,
} from "../../../../components";
import { HandleGoBack } from "../../../../components/handleGoBack/HandleGoBack";
import { Tabs } from "../../../../components/Tabs/Tabs";
import { RetailAccountForm } from "../../RetailOnboard/RetailAccountForm";

export const SingleAccount = () => {
  const tabs = [
    {
      id: "corporate",
      label: "Corporate",
      content: <SingleAccountForm />,
    },
    {
      id: "retail",
      label: "Retail",
      content: <RetailAccountForm />,
    },
    // Add more tabs as needed
  ];
  return (
    <div>
      <Header>
        <div>
          <Heading>Account onboarding</Heading>
          <p className="mt-1">Create account</p>
        </div>
      </Header>
      <div className="mb-4 flex items-center justify-between">
        <SubHeading>Create a new account.</SubHeading>
        <HandleGoBack />
      </div>
      <div className="w-[500px]">
        <Container>
          <Tabs tabs={tabs} />
        </Container>
      </div>
    </div>
  );
};
