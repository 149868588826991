import React, { useState } from "react";

export const TabFilter = ({
  data = [
    {
      time: "All",
    },
    {
      time: "Today",
    },
    {
      time: "Yesterday",
    },
    {
      time: "Archived",
    },
    {
      time: "Last 30 days",
    },
  ],
}) => {
  const [activeTab, setActiveTab] = useState(data[0].time);

  const handleTabClick = (time) => {
    setActiveTab(time);
  };

  return (
    <div className="flex gap-5 grow-0  lg:w-[100%]">
      {data.map((datum) => {
        const isActive = datum.time === activeTab;
        return (
          <p
            key={datum.time}
            onClick={() => handleTabClick(datum.time)}
            className={` cursor-pointer pb-2 ${
              isActive
                ? "text-primary rounded-lg bg-[#e7802031]  w-fit px-2 pt-1 flex items-center justify-center flex-shrink-0 font-semibold"
                : "text-gray-700 px-2  pt-1 border rounded-lg flex items-center justify-center w-fit"
            } text-[15px]`}
          >
            {datum.time}
          </p>
        );
      })}
    </div>
  );
};
