import { forwardRef } from "react";
import ErrorMessage from "../Error/ErrorMessage";
import { Label } from "../Label/Label";

export const TextArea = forwardRef((props, ref) => {
  const { id, placeholder, onChange, label, error } = props;
  return (
    <div className="relative">
      <div className="mb-2">{label && <Label id={id} label={label} />}</div>
      <textarea
        name={id}
        id={id}
        rows="8"
        ref={ref}
        className="relative resize-none block w-full bg-[transparent] appearance-none rounded-md  border border-[#e7802085]  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#e780206b] focus:outline-none focus:ring-[#e780206b] sm:text-sm"
        placeholder={placeholder}
        onChange={onChange}
      />
      {error && <ErrorMessage msg={error} />}
    </div>
  );
});

TextArea.displayName = "TextArea";
