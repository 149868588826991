import React, { useMemo, useState } from "react";
import {
  Button,
  Container,
  Header,
  Heading,
  SubHeading,
} from "../../../../components";
import { DocumentDownload, Import } from "iconsax-react";
import testCsv from "../../../../assets/documents/test.xlsx";
import { BatchUpload } from "../../../Transfers/CreateTransfer/BatchUpload/BatchUpload";
import { useConvertFileToJson } from "../../../../hooks";
export const BatchOnboard = () => {
  const { convertFile, file, clearFile, jsonArray } = useConvertFileToJson();
  const [stages, setStages] = useState("step 1/3-upload file");

  return (
    <div>
      <Header>
        <Heading>Batch Onboard</Heading>
        <p className="mt-1">Onboard batch accounts</p>
      </Header>

      <div className="flex justify-between items-center">
        <SubHeading>Onboard batch account with so much ease.</SubHeading>
        <Button variant="outline">
          <a href={testCsv} className="flex items-center gap-2">
            Template
            <DocumentDownload className="h-5 w-5 text-primary" />
          </a>
        </Button>
      </div>
      <div className=" mt-6">
        <div>
          <BatchUpload
            convertFile={convertFile}
            file={file}
            clearFile={clearFile}
            jsonArray={jsonArray}
            stages={stages}
            setStages={setStages}
          />
        </div>
      </div>
    </div>
  );
};
