import { Calendar1 } from "iconsax-react";

export const ApiTable = ({ data, initialSerialNumber }) => {
  return (
    <div className="rounded-sm overflow-hidden overflow-x-auto mb-4">
      <table className="min-w-full ">
        <thead>
          <tr>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              S/N
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Organisation Name
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Number of Enquiry
            </th>
            <th className="px-3 py-3 text-left text-xs font-bold text-gray-800 uppercase tracking-wider text-[12px]">
              Date & Time created
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-800">
          {data?.map((item, i) => (
            <tr key={item.id}>
              <td className="px-3 py-4 text-[13px] whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {initialSerialNumber ? initialSerialNumber + i : i + 1}.
              </td>

              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {item?.name}
              </td>
              <td className="px-3 py-4 text-[13px]   whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                {item.total}
              </td>
              <td className="px-3 py-4 text-[13px]  whitespace-nowrap text-[#6E6B7B] border-b border-[#c0c0c052]">
                <div className="flex items-center gap-2">
                  <Calendar1 variant="Bold" className="w-5 h-5 text-primary" />
                  <p> {item.time}</p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
