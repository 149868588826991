import { useNavigate } from "react-router-dom";
import { Button, SubHeading } from "../../../../components";
import { formatNaira } from "../../../../utils";

export const LoanProductId = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="border bg-orange-50 rounded-lg p-6 space-y-4">
      <div className=" flex justify-between items-center">
        <SubHeading>{`Minimum of ${formatNaira(
          data?.min_loan_amount
        )} to maximum of ${formatNaira(data?.max_loan_amount)}`}</SubHeading>
      </div>
      <p className=" text-sm text-gray-400 italic">
        {data?.interest_rate}% interest over {data?.max_tenor}{" "}
        {data?.tenor_type}
      </p>
      <div className="h-1 border-b rounded-xl mt-3" />
      <div>
        <div>
          <h1 className="flex mb-2 items-center gap-1 font-semibold font-doppin">
            <span className="w-[7px]  h-[7px] rounded-full bg-black block"></span>
            Description
          </h1>
          <p className="  w-[500px] text-sm">{data?.description}</p>
        </div>
        <div className="p-6  w-[500px] mt-6 border-2 border-primary rounded-lg">
          <h1 className=" font-doppin">Requirements</h1>
          <ul className="space-y-4 mt-3">
            {data?.requirements?.map((item, i) => (
              <li key={i} className=" list-disc list-inside text-sm">
                {item?.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="w-[300px]">
        <Button
          isFullWidth
          onClick={() => {
            navigate(`/loan-products/${data?.id}/loan-applications/create`);
          }}
        >
          Apply for loan
        </Button>
      </div>
    </div>
  );
};
