import React from "react";

const Logo = ({ children = "SUMMER-MFB" }) => {
  return (
    <div
      className={`bg-white text-primary font-bold h-[50px] flex justify-center items-center rounded-lg text-[23px]`}
    >
      {children}
    </div>
  );
};

export default Logo;
