import React from "react";
import { Badge, SubHeading } from "../../../components";

export const Profile = ({ user , role}) => {
  return (
    <div className="p-3">
      <SubHeading>
        User details for <span className="capitalize">{user?.first_name} {user?.last_name}</span>
      </SubHeading>
      <dl className="flex flex-col gap-6 mt-6">
        <dt className="flex justify-between items-center border-b border-gray-300 py-3">
          <p className="text-[#9FA2AB] text-[15px]">First Name</p>
          <p className="text-[15px] capitalize">{user?.first_name}</p>
        </dt>
        <dt className="flex justify-between items-center border-b border-gray-300   py-3">
          <p className="text-[#9FA2AB] text-[15px]">Last Name</p>
          <p className="text-[15px] capitalize">{user?.last_name}</p>
        </dt>
        <dt className="flex justify-between items-center border-b border-gray-300  py-3">
          <p className="text-[#9FA2AB] text-[15px]">Email</p>
          <p className="text-[15px]">{user?.email}</p>
        </dt>
        <dt className="flex justify-between items-center border-b border-gray-300  py-3">
          <p className="text-[#9FA2AB] text-[15px]">Phone Number</p>
          <p className="text-[15px]">{user?.phone_number}</p>
        </dt>
        <dt className="flex justify-between items-center border-b border-gray-300  py-3">
          <p className="text-[#9FA2AB] text-[15px]">Status</p>
          <div className="text-[15px]">
            <Badge status={user?.status}>{user?.status}</Badge>
          </div>
        </dt>
      </dl>
    </div>
  );
};
