import { useQuery } from "@tanstack/react-query";
import { Badge, Button, Card, Header, Heading } from "../../../components";
import { loanService } from "../../../services/loan/loan.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatDate, formatNaira, truncateText } from "../../../utils";
import { ArrowCircleDown, ArrowLeft, Link1, Money } from "iconsax-react";
import { useModal } from "../../../hooks";
import {
  LoanApproval,
  LoanDisburseApproval,
  LoanDisburseRejection,
  LoanRejection,
} from "../LoanProductType/components/LoanApproval";
import { Spinner } from "../../../components/Spinner";
import { userService } from "../../../services/users/user.service";

export const LoanApplicationId = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { Modal, showModal } = useModal();
  const { Modal: Modal2, showModal: showModal2 } = useModal();
  const isURL = (str) => {
    const pattern = new RegExp(
      "^(https?://)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return pattern.test(str);
  };

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["loan-applicationId", id],
    queryFn: () => loanService.getLoanApplication(id),
    enabled: !!id,
  });
  const customer_id = data?.data?.customer_id;
  const { data: user } = useQuery({
    queryKey: ["loan-user", customer_id],
    queryFn: () => userService.getUser(customer_id),
    enabled: !!customer_id,
  });

  const { data: decisions, refetch: refetchDecisions } = useQuery({
    queryKey: ["loan-decision", id],
    queryFn: () =>
      loanService.getLoanApplicationDecision({ loan_application_id: id }),
    enabled: !!id,
  });

  const cardData = [
    {
      title: "Loaned Amount",
      amount: formatNaira(data?.data?.loan_amount ?? "00"),
    },
    {
      title: "Repayment Amount",
      amount: formatNaira(data?.data?.repayment_amount ?? "00"),
    },
    {
      title: "Loan Interest",
      amount: `${formatNaira(data?.data?.interest ?? "00")}`,
    },
  ];
  return (
    <div>
      <Header>
        <div className="flex items-center gap-6">
          <ArrowLeft
            onClick={() => {
              navigate(-1);
            }}
            variant="Bold"
            className="text-primary cursor-pointer"
          />
          <Heading>
            Loan application for
            <span className="ml-1">{`${user?.first_name ?? ".."} ${
              user?.last_name ?? ".."
            }`}</span>
          </Heading>
        </div>
      </Header>
      {isFetching ? (
        <div className="h-[50vh] flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div>
          {data?.data.status === "awaiting disbursement" ? (
            <div className=" flex justify-end gap-6">
              <Button onClick={showModal}>Approve loan disbursal</Button>
              <Button onClick={showModal2} variant="danger">
                Reject loan disbursal
              </Button>
            </div>
          ) : null}
          {data?.data.status === "awaiting approval" ? (
            <div className=" flex justify-end gap-6">
              <Button onClick={showModal}>Approve loan</Button>
              <Button onClick={showModal2} variant="danger">
                Reject loan
              </Button>
            </div>
          ) : null}

          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 flex justify-between items-center">
              <div>
                <span className="mr-2 font-semibold">Status:</span>
                <Badge status={data?.data?.status}>
                  {data?.data?.status.replace("_", " ")}
                </Badge>
              </div>
              {data?.data?.status === "disbursed" && (
                <div>
                  <Link
                    to={`/loan-products/loan-applications/repayment-schedule/${id}`}
                    className="text-sm text-primary font-semibold block w-fit border border-primary p-2 rounded-md"
                  >
                    View repayment schedule
                  </Link>
                </div>
              )}
            </div>
            {cardData.map((item, i) => (
              <div className="col-span-4">
                <Card>
                  <div className="flex text-primary items-center gap-4">
                    <Money className="w-5 h-5" />
                    <p className="text-primary">{item?.title}</p>
                  </div>
                  <div>
                    <Heading>
                      <p className="mt-3 text-primary">{item?.amount}</p>
                    </Heading>
                  </div>
                </Card>
              </div>
            ))}
            <div className="mt-6 col-span-8 ">
              <h1 className="flex items-center gap-1 font-semibold font-doppin">
                <span className="w-[7px]  h-[7px] rounded-full bg-black block"></span>
                Requirements
              </h1>
              <div className="border shadow-md  rounded-md  mt-6">
                <div className="space-y-4">
                  {data?.data?.payload?.map((requirement, i) => (
                    <div key={i} className="border-t">
                      <div className="grid grid-cols-2 items-center justify-between py-5 px-6">
                        <p className=" text-gray-600 font-lighter font-[500]">
                          {requirement.label}:
                        </p>
                        {typeof requirement.value === "boolean" && (
                          <p className="text-sm text-gray-500">
                            {requirement.value === true ? "Yes" : "No"}
                          </p>
                        )}

                        {isURL(requirement.value) ? (
                          <p className="flex items-center gap-2">
                            {" "}
                            <a
                              href={requirement.value}
                              target="_blank"
                              className="text-sm  underline text-primary"
                              rel="noopener noreferrer"
                            >
                              {truncateText(requirement.value, 30)}
                            </a>
                            <Link1 className="text-gray-500 text" />
                          </p>
                        ) : (
                          <p className="text-sm text-gray-500">
                            {requirement.value}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {decisions?.map((item, i) => (
              <div className="col-span-4 ">
                <h1 className="flex my-4 items-center gap-1 font-semibold font-doppin">
                  <span className="w-[7px]  h-[7px] rounded-full bg-black block"></span>
                  Decisions
                </h1>
                <div
                  key={i}
                  className="p-5 grid grid-cols-12  gap-4 border-2 rounded-2xl border-primary"
                >
                  <p className="col-span-6  text-[15px]">Decision:</p>
                  <p className="col-span-6 text-gray-400">{item.decision}</p>
                  <p className="col-span-6">Reason:</p>
                  <p className="col-span-6 text-gray-400 text-[15px]">
                    {item?.reason}
                  </p>
                  <p className="col-span-6">Time created:</p>
                  <p className="col-span-6 text-gray-400 text-[15px]">
                    {formatDate(item?.created_at)}
                  </p>
                  <p className="col-span-6">Time updated:</p>
                  <p className="col-span-6 text-gray-400 text-[15px]">
                    {formatDate(item?.updated_at)}
                  </p>
                </div>
                {i !== decisions?.length - 1 && (
                  <ArrowCircleDown
                    className="mx-auto mt-2 text-primary"
                    variant="Bold"
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      <div>
        {Modal({
          children: (
            <div>
              {data?.data.status === "awaiting disbursement" && (
                <LoanDisburseApproval
                  loan_application={data?.data}
                  refetch={refetch}
                  showModal={showModal}
                  refetch2={refetchDecisions}
                />
              )}
              {data?.data.status === "awaiting approval" && (
                <LoanApproval
                  showModal={showModal}
                  refetch={refetch}
                  loan_application={data?.data}
                  refetch2={refetchDecisions}
                />
              )}
            </div>
          ),
        })}

        {Modal2({
          children: (
            <div>
              {data?.data.status === "awaiting disbursement" && (
                <LoanDisburseRejection
                  loan_application={data?.data}
                  refetch={refetch}
                  showModal={showModal2}
                  refetch2={refetchDecisions}
                />
              )}
              {data?.data.status === "awaiting approval" && (
                <LoanRejection
                  showModal={showModal2}
                  refetch={refetch}
                  loan_application={data?.data}
                  refetch2={refetchDecisions}
                />
              )}
            </div>
          ),
        })}
      </div>
    </div>
  );
};
