import { Filter, FilterSquare, FilterTick } from "iconsax-react";
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useClickOutside } from "../../hooks/useClickOutside";
let optionsTest = ["Option 1", "Option 2", "Option 3"];
export const FilterComponent = ({
  onSelect,
  options = optionsTest,
  Icon = FilterTick,
  color = "text-primary",
  styles = "border-[1px] border-[#e7802071] rounded-[5px]",
  dropStyles = "top-[50px] right-0 bg-white border w-[200px]",
  placeholder = "Filter by",
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { handleClickOutside, dropdownRef, isOpen, setIsOpen } =
    useClickOutside();

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={`px-5 py-2  text-gray-800 rounded-[40px] ${styles} focus:outline-none`}
      >
        <div className={`flex items-center gap-3 ${color}`}>
          <span className="text-sm">{selectedOption || placeholder}</span>
          <Icon className="w-4 h-4" />
        </div>
      </button>
      {isOpen && (
        <ul className={`absolute  rounded shadow-md ${dropStyles}`}>
          {options.map((option) => (
            <li
              key={option}
              className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-[13px]"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
