import React from "react";
import { twMerge } from "tailwind-merge";

export const Label = ({ id, label }) => {
  return (
    <label htmlFor={id} className="block text-[15px] font-medium text-gray-700">
      {label}
    </label>
  );
};

export const Label2= ({ htmlFor, children, className, ...props }) => {
	return (
		<label htmlFor={htmlFor} className={twMerge("block text-[13px] mb-2 text-gray-600", className)}>
			{children}
		</label>
	);
};