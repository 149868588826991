import { useState } from "react";
import { limit } from "../../../../../constants/limit";
import { useMutation, useQuery } from "@tanstack/react-query";
import { userService } from "../../../../../services/users/user.service";
import {
  ExportButton,
  Pagination,
  SubHeading,
} from "../../../../../components";
import {
  useConvertFileToJson,
  useTableSerialNumber,
} from "../../../../../hooks";
import ContentLoader from "react-content-loader";
import { RenderData } from "../../../../UserManagement/components/RenderData";
import { useParams } from "react-router-dom";
import SearchFilter from "../../../../../components/SearchFilter/SearchFilter";

export const UserAccount = () => {
  const [page, setPage] = useState(1);
  const [value, setValue] = useState(undefined);
  const { id } = useParams();
  const params = {
    limit: limit,
    page: page,
    account_id: id,
    search:value
  };
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ["users", params],
    queryFn: () => userService.getUsers(params),
  });
  const { mutate } = useMutation({
    mutationKey: ["resend-email"],
    mutationFn: (data) => userService.resendEmailVerification(data),
    onSuccess: () => {
      refetch();
    },
  });
  const { convertJsonToExcel } = useConvertFileToJson("users");
  const { mutate: downloadUsers, isPending } = useMutation({
    mutationKey: ["download-users"],
    mutationFn: (data) => userService.getUser(data),
    onSuccess: (data) => {
      const users = data?.items?.map((dat) => {
        return {
          FirstName: dat?.first_name,
          LastName: dat?.last_name,
          Email: dat?.email,
          PhoneNumber: dat?.phone_number,
          Gender: dat?.gender,
          ID: dat?._id,
        };
      });
      console.log(users);
      convertJsonToExcel(users);
    },
  });
  const initialSerialNumber = useTableSerialNumber(page);
  return (
    <div>
      <div className="flex flex-col lg:flex-row lg:items-center item-start justify-between my-10">
        <div>
          <SubHeading>List of user created under this account.</SubHeading>
         <div className="mt-4"> 
         <SearchFilter
            placeholder="Search by email, name or phone number"
            value={value}
            position="none"
            setValue={setValue}
            onSearch={() => {
              refetch();
            }}
          />
         </div>
        </div>
        {data && (
          <ExportButton
            onClick={downloadUsers}
            disabled={isPending}
            name="Users"
          >
            export users
          </ExportButton>
        )}
      </div>

      {isLoading || isFetching ? (
        <ContentLoader viewBox="0 0 380 70">
          <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
        </ContentLoader>
      ) : (
        <div>
          <RenderData
            initialSerialNumber={initialSerialNumber}
            data={data?.items}
            mutate={mutate}
          />
          <div className="flex items-center justify-center mt-2">
            <Pagination
              itemsPerPage={limit}
              totalItems={data?.meta?.total}
              currentPage={page}
              handlePageClick={setPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};
