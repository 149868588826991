import { EmptyState } from "../../../../components";
import { LoanTable } from "./LoanTable";

export const RenderData = ({ data, refetch, initialSerialNumber }) => {
  if (data?.length === 0 || !data) {
    return (
      <EmptyState
        title="No loan application found"
        description="Please check back your internet connection or come back later"
        showAction
      />
    );
  } else {
    return (
      <LoanTable
        initialSerialNumber={initialSerialNumber}
        refetch={refetch}
        data={data}
      />
    );
  }
};
