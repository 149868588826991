import React from "react";
import { ApiWrapper } from "../components/ApiWrapper";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
import { BvnTable } from "./BvnTable";
import Entries from "../../../components/Filter/Entries";
import { Container, Pagination } from "../../../components";
import { ApiData } from "../components/MockData";
import { usePagination, useTableSerialNumber } from "../../../hooks";
import { TabFilter } from "../../Dashboard/component/TabFilter";

export const Bvn = () => {
  const { paginatedData, page, setPage } = usePagination(ApiData);
  const initialSerialNumber = useTableSerialNumber(page);
  return (
    <div>
      <ApiWrapper>
        <div className="mt-7 flex flex-col gap-5">
          <TabFilter />
          <BvnTable
            data={paginatedData}
            initialSerialNumber={initialSerialNumber}
          />
          {ApiData.length !== 5 && (
            <div className="flex items-center justify-center mt-4">
              <Pagination
                totalItems={ApiData.length}
                currentPage={page}
                handlePageClick={setPage}
              />
            </div>
          )}
        </div>
      </ApiWrapper>
    </div>
  );
};
