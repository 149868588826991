import React, { useState } from "react";
import {
  Button,
  ExportButton,
  Header,
  Heading,
  Pagination,
  SubHeading,
} from "../../components";
import SearchFilter from "../../components/SearchFilter/SearchFilter";
import { useConvertFileToJson, useModal, useTableSerialNumber } from "../../hooks";
import { limit } from "../../constants/limit";
import { userService } from "../../services/users/user.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import ContentLoader from "react-content-loader";
import { RenderData } from "./components/RenderData";
import { PlusIcon } from "@heroicons/react/24/outline";
import { AddUser } from "./components/AddUser";

export const UserManagement = ({ noHeader = false }) => {
  const [value, setValue]=useState(undefined)
  const [page, setPage] = useState(1);
  const params = {
    limit: limit,
    page: page,
    search:value
  };
  const {Modal,showModal}=useModal()
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ["reps", params],
    queryFn: () => userService.getRep(params),
  });
  const { mutate } = useMutation({
    mutationKey: ["resend-email"],
    mutationFn: (data) => userService.resendEmailVerification(data),
    onSuccess: () => {
      refetch();
    },
  });
  const { convertJsonToExcel } = useConvertFileToJson("admins");
  const { mutate: downloadUsers, isPending } = useMutation({
    mutationKey: ["download-reps"],
    mutationFn: (data) => userService.getRep(data),
    onSuccess: (data) => {
      const users = data?.items?.map((dat) => {
        return {
          FirstName: dat?.first_name,
          LastName: dat?.last_name,
          Email: dat?.email,
          PhoneNumber: dat?.phone_number,
          ID: dat?._id,
        };
      });
      convertJsonToExcel(users);
    },
  });
  const initialSerialNumber = useTableSerialNumber(page);
  return (
    <div>
      {noHeader === false ? (
        <div>
          <Header>
            <div className="flex items-center gap-3">
              <Heading>Admin Management</Heading>
              <SearchFilter
                placeholder="Search by email, first name, last name or phone number"
                value={value}
                setValue={setValue}
                onSearch={refetch}
              />
            </div>
          </Header>
          <div className="flex flex-col lg:flex-row lg:items-center item-start justify-between my-4">
            <SubHeading>List of admins created under this account.</SubHeading>
           <div className="flex items-center gap-6">
           <Button variant="outline" onClick={showModal}>
            <PlusIcon className="w-5 h-5 text-primary" />
            <span className="text-primary font-semibold ml-1">
              Create Admin
            </span>
          </Button>
          {data && (
              <ExportButton
                onClick={downloadUsers}
                disabled={isPending}
                name="Users"
              >
                export users
              </ExportButton>
            )}
           </div>
            
          </div>
        </div>
      ) : (
        <div className="flex flex-col lg:flex-row lg:items-center item-start justify-between my-10">
          <SubHeading>List of users created under this account.</SubHeading>

          {data && (
            <ExportButton
              onClick={downloadUsers}
              disabled={isPending}
              name="Users"
            >
              export users
            </ExportButton>
          )}
        </div>
      )}

      {isLoading || isFetching ? (
        <ContentLoader viewBox="0 0 380 70">
          <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
        </ContentLoader>
      ) : (
        <div>
          <RenderData
            mutate={mutate}
            initialSerialNumber={initialSerialNumber}
            data={data?.items}
          />
          <div className="flex items-center justify-center mt-2">
            <Pagination
              itemsPerPage={limit}
              totalItems={data?.meta?.total}
              currentPage={page}
              handlePageClick={setPage}
            />
          </div>
        </div>
      )}
       {Modal({
        children: (
          <div>
            <p className="font-semibold text-[16px] mb-4">Create Admin</p>
            <AddUser showModal={showModal} refetch={refetch} />
          </div>
        ),
      })}
    </div>
  );
};
