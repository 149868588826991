import React from "react";
import {Money4} from "iconsax-react";
import { useState } from "react";

export const Card = ({ card, children, isLoading }) => {
  const [toggle, setToggle] = useState(true);
  const handleToggle = () => {
    if (toggle === true) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  };

  return (
    <div className="rounded-[20px] p-7 bg-[#fff]  shadow-sm  border-[1px]">
      {card && (
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="flex items-center gap-3">
              <span className="w-[55px] h-[55px] flex items-center justify-center bg-[#e780201e] rounded-full">
                {card?.icon ? (
                  <card.icon
                    variant="Bold"
                    className="block w-6 h-6 text-primary"
                  />
                ) : (
                  <Money4
                    variant="Bold"
                    className="block w-6 h-6 text-primary"
                  />
                )}
              </span>
            </div>
            <div className="mt-4">
              <p className=" text-[15px]">{card?.title}</p>
              <div className="mt-2">
                {isLoading || card?.amount === undefined ? (
                  <p className="text-[18px] font-semibold text-gray-600">
                    ......
                  </p>
                ) : (
                  <h2 className="text-[18px] font-semibold text-gray-600">
                    {card?.amount}
                  </h2>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};
