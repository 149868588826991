import ContentLoader from "react-content-loader";
import { ExportButton, Header, Heading, Pagination } from "../../../components";
import { RenderData } from "../LoanProductType/components/RenderData";
import { useMutation, useQuery } from "@tanstack/react-query";
import { loanService } from "../../../services/loan/loan.service";
import { limit } from "../../../constants/limit";
import { useState } from "react";
import { useConvertFileToJson, useTableSerialNumber } from "../../../hooks";
import { formatNaira } from "../../../utils";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";

export const LoanApplications = () => {
  const [page, setPage] = useState(1);
  const [value, setValue]=useState(undefined)
  const params = {
    page: page,
    limit: limit,
    search:value
  };
  const {
    data: loanApplication,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["loan-applications", params],
    queryFn: () => loanService.getLoanApplications(params),
  });
  const initialSerialNumber = useTableSerialNumber(page);
  const { convertJsonToExcel } = useConvertFileToJson("loan applications");
  const { isPending: isDownloading, mutate: downloadLoanApplications } =
    useMutation({
      mutationKey: ["loan-applications-list", params],
      mutationFn: () => loanService.getLoanApplications(params),
      onSuccess: (data) => {
        const applications = data?.data?.items?.map((dat) => {
          return {
            Product: dat?.loan_product?.name,
            Status: dat?.status,
            Amount: formatNaira(dat?.loan_amount),
            Repayment_Amount: formatNaira(dat?.repayment_amount),
            Interest: formatNaira(dat?.interest),
          };
        });

        convertJsonToExcel(applications);
      },
    });

  return (
    <div>
      <Header>
        <div className="flex flex-col lg:flex-row lg:items-center items-start ml-2 lg:ml-0">
        <Heading>Loan Applications</Heading>
          <SearchFilter
            placeholder={"Search by loan mount or customer..."}
            noBorder={true}
            value={value}
            setValue={setValue}
            onSearch={refetch}
          />
        </div>
      </Header>
      <div className="grid grid-cols-12 gap-7">
        <div className="col-span-12 mt-8">
          <div className="flex justify-between items-center mb-6">
            <h1 className="font-semibold">List of loan applications</h1>
            <ExportButton
              onClick={downloadLoanApplications}
              disabled={isDownloading}
            >
              Export loan application data
            </ExportButton>
          </div>
          {isFetching ? (
            <ContentLoader viewBox="0 0 380 70">
              <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
            </ContentLoader>
          ) : (
            <div>
              <RenderData
                refetch={refetch}
                initialSerialNumber={initialSerialNumber}
                data={loanApplication?.data?.items}
              />
              <div className="flex items-center justify-center mt-4">
                <Pagination
                  itemsPerPage={params?.limit}
                  totalItems={loanApplication?.data.meta?.total}
                  currentPage={page}
                  handlePageClick={setPage}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
