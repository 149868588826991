import { Pagination } from "../../../../components";
import { useState } from "react";

const postPerPage = 10;

export const BatchTable = ({ items }) => {
  const [page, setPage] = useState(1);
  const currentPage = items.slice(
    page * postPerPage - postPerPage,
    page * postPerPage
  );

  return (
    <>
      <div className="relative overflow-x-auto mt-6">
        <div>
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-sm bg-gray-100 border text-black uppercase ">
              <tr>
                <th scope="col" className="pl-6 py-3 text-[12px]">
                  ACCOUNT NAME/NUMBER
                </th>
                <th scope="col" className="pl-6 py-3 text-[12px]">
                  CUSTOMER ID
                </th>
                <th scope="col" className="pl-6 py-3 text-[12px]">
                  ACCOUNT TYPE
                </th>
                <th scope="col" className="pl-6 py-3 text-[12px]">
                  ADMIN EMAIL
                </th>
                <th scope="col" className="pl-6 py-3 text-[12px]">
                  ADMIN FULL NAME
                </th>
                <th scope="col" className="pl-6 py-3 text-[12px]">
                  GENDER
                </th>
                <th scope="col" className="pl-6 py-3 text-[12px]">
                  ADMIN PHONE NUMBER
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPage.map((value, index) => (
                <tr className="border" key={index}>
                  <td className="px-6 py-4 border-l">
                    <div>{value["ACCOUNT_NAME"]}</div>
                    <div>{value["ACCOUNT_NUMBER"]}</div>
                  </td>
                  <td className="px-6 py-4 border-l">
                    <div>{value["CUSTOMER_ID"]}</div>
                  </td>
                  <td className="px-6 py-4 border-l">
                    {value["ACCOUNT_TYPE"]}
                  </td>
                  <td className="px-6 py-4 border-l">{value["ADMIN_EMAIL"]}</td>

                  <td className="px-6 py-4 border-l">
                    <p>
                      {" "}
                      {value["ADMIN_FIRST_NAME"]} {value["ADMIN_LAST_NAME"]}
                    </p>
                  </td>
                  <td className="px-6 py-4 border-l">{value["GENDER"]}</td>
                  <td className="px-6 py-4 border-l">
                    {value["ADMIN_PHONE_NUMBER"]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        totalItems={items.length}
        itemsPerPage={postPerPage}
        handlePageClick={setPage}
        currentPage={page}
      />
    </>
  );
};
