import ContentLoader from "react-content-loader";
import {
  Header,
  Heading,
  Pagination,
} from "../../../components";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
import { RenderData } from "../components/RenderData";
import { Tabs } from "../../../components/Tabs/Tabs";
import { Add } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import Chart from "../../../components/Charts/Chart";
import { useQuery } from "@tanstack/react-query";
import { loanService } from "../../../services/loan/loan.service";
import { limit } from "../../../constants/limit";
import { useState } from "react";
import { useTableSerialNumber } from "../../../hooks";

export const LoanProduct = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [value, setValue]=useState()
  const params = {
    page: page,
    limit: limit,
    search:value,
  };
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["loan-products", params],
    queryFn: () => loanService.getLoanProducts(params),
  });

  const initialSerialNumber = useTableSerialNumber(page);

  const tabs = [
    {
      id: "products",
      label: "Products",
      content: isLoading ? (
        <ContentLoader viewBox="0 0 380 70">
          <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
        </ContentLoader>
      ) : (
        <div>
          <div className="flex justify-between items-center my-4">
            <p className="font-bold">List of loan products</p>
            <span
              onClick={() => {
                navigate("/loan-products/add-product");
              }}
              className="flex items-center cursor-pointer gap-2 text-[14px] font-semibold text-primary border-[1px] border-[#e780208a] px-3 py-2 rounded-[5px]"
            >
              <Add className="w-5 h-5" />
              <span>add a new product</span>
            </span>
          </div>
          <div>
            <RenderData
              initialSerialNumber={initialSerialNumber}
              data={data?.items}
            />
            <div className="flex items-center justify-center mt-4">
              <Pagination
                itemsPerPage={params?.limit}
                totalItems={data?.meta?.total}
                currentPage={page}
                handlePageClick={setPage}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      id: "report",
      label: "Reports",
      content: (
        <div>
          {/* <div className="my-6 w-[300px]">
            <Select
              control={control}
              name="filter"
              placeholder="Product Loans vs Amount Disbursed"
              options={[]}
            />
          </div> */}
          <Chart isloading={isLoading} data={data} title="Loan Report" />
        </div>
      ),
    },
  ];
  return (
    <div>
      {" "}
      <Header>
        <div className="flex flex-col lg:flex-row lg:items-center items-start ml-2 lg:ml-0">
          <Heading>Loan Products</Heading>
          <SearchFilter
            placeholder={"Search by loan product name or product code..."}
            noBorder={true}
            value={value}
            setValue={setValue}
            onSearch={refetch}
          />
        </div>
      </Header>
      <div className="">
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
};
