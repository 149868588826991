import { UserGroupIcon } from "@heroicons/react/24/outline";
import {
  Element2,
  Money,
  Setting2,
  Trade,
  User,
  MoneyAdd,
  Setting4,
  Briefcase,
} from "iconsax-react";
export const data = [
  {
    title: "dashboard",
    to: "/dashboard",
    iconLinear: <Element2 className="w-5 h-5" />,
    iconBold: <Element2 variant="Bold" className="w-5 h-5" />,
  },
  {
    title: "accounts",
    to: "/accounts",
    iconLinear: <UserGroupIcon className="w-5 h-5" />,
    iconBold: <Money variant="Bold" className="w-5 h-5" />,
  },

  {
    title: "Admins",
    to: "/admin-management",
    iconLinear: <User className="w-5 h-5" />,
    iconBold: <User variant="Bold" className="w-5 h-5" />,
  },

  {
    title: "Loan",
    to: "/loan-products",
    iconLinear: <Briefcase className="w-5 h-5" />,
    iconBold: <Briefcase variant="Bold" className="w-5 h-5" />,
  },

  {
    title: "profile",
    to: "/profile",
    iconLinear: <User className="w-5 h-5" />,
    iconBold: <User variant="Bold" className="w-5 h-5" />,
  },

  {
    title: "settings",
    to: "/settings",
    iconLinear: <Setting4 className="w-5 h-5" />,
    iconBold: <Setting2 variant="Bold" className="w-5 h-5" />,
  },
];
