import { Button, Select, Input } from "../../../../components";

export const FormStep1 = ({
  errors,
  register,
  handleSubmit,
  handlePrev,
  handleNext,
  control,
  currentStep,
}) => {
  const onSubmit = () => {
    handleNext();
  };
  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center justify-between pt-3">
        <p className="font-bold text-lg">Other info</p>
        <p> {`${currentStep}/4`}</p>
      </div>
      <Input
        label="Product code"
        id="product_code"
        {...register("product_code", { required: true })}
        error={errors.product_code && "Product code is required"}
      />
      <Input
        label="Transaction tracking ref"
        id="transaction_tracking_ref"
        {...register("transaction_tracking_ref", { required: true })}
        error={errors.transaction_tracking_ref && "This field is required"}
      />
      <Input
        label="Account opening tracking ref"
        id="account_opening_tracking_ref"
        {...register("account_opening_tracking_ref", { required: true })}
        error={
          errors.account_opening_tracking_ref &&
          "Account opening tracking ref is required"
        }
      />
      <Input
        label="Account reference number"
        id="account_reference_number"
        {...register("account_reference_number", { required: true })}
        error={
          errors.account_reference_number && "Account ref number is required"
        }
      />
      <Select
        label="Sufficient info"
        name="has_sufficient_info_on_account_info"
        control={control}
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
        error={
          errors.has_sufficient_info_on_account_info &&
          "Sufficient information is required"
        }
      />
      <Select
        label="Transaction Permission"
        name="transaction_permission"
        control={control}
        options={[
          { label: 0, value: 0 },
          { label: 1, value: 1 },
        ]}
        error={
          errors.transaction_permission && "Transaction permission is required"
        }
      />
      <Input
        label="Customer ID"
        id="customer_id"
        {...register("customer_id", { required: true })}
        error={errors.customer_id && "Customer ID is required"}
      />

      <div className="flex items-center justify-between pt-4">
        <Button type="button" variant="outline" onClick={handlePrev}>
          Back
        </Button>
        <Button type="submit">Continue</Button>
      </div>
    </form>
  );
};
